import { ChangeEvent, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import Select, { ActionMeta } from "react-select";

import { isMobile } from "utils/utils";
import { EditModalTypes, NewTask } from "types/tasks.types";
import { UserOption } from "types/assign-to-user.types";
import useUsersForSelect from "hooks/useUsersForSelect";
import { getTranslations as taskCreatorTranslations } from "../../translations/task-creator.translations";
import { getTranslations as getSelectTranslations } from "translations/select.translations";
import { getTranslations as getTasksTranslations } from "pages/user-tasks/translations/tasks.translations";

import ShimmerLoader from "components/atoms/loaders/ShimmerLoader";
import AttachmentIcons from "./components/attachment-icons/AttachmentIcons";
import TaskComment from "pages/selected-task-created/components/task-comment/TaskComment";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import CloseIcon from "components/molecules/close-icon/CloseIcon";

import { Card, Form, FormField, StyledLabel, ModalStyles } from "styles/generalStyles";
import { TaskTitle, TaskTitleContainer } from "styles/selected-task.styles";
import { DeadlineInputWrapper, Placeholder } from "../../styles/task-creator.styles";
import { selectControlStyles } from "styles/select.styles";
import { Header, FormContainer } from "../../styles/task-creator.styles";

const { Actions, ButtonContainer } = ModalStyles;

const { TITLE } = EditModalTypes;

type CreateTaskFormProps = {
  newTask: NewTask;
  disabled: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onTextFieldClick: (modalType: EditModalTypes) => () => void;
  createTaksLoading: boolean;
  onAssignUserChange: (
    option: UserOption | null,
    { action }: ActionMeta<UserOption>,
  ) => void;
  onDeadlineInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCloseIconClick: () => void;
};

function CreateTaskForm({
  newTask,
  disabled,
  onSubmit,
  onTextFieldClick,
  createTaksLoading,
  onAssignUserChange,
  onDeadlineInputChange,
  onCloseIconClick,
}: CreateTaskFormProps) {
  const { userOptions, isLoading } = useUsersForSelect();
  const { id, title, comment, deadline, selectedUser } = newTask;
  const userAssigned = userOptions?.find(({ id }) => id === selectedUser?.id);

  const {
    i18n: { language },
  } = useTranslation();

  const { labels, titles, placeholders } = taskCreatorTranslations(language);
  const { label, placeholder, selectNoOptionsMessage } = getSelectTranslations(language);
  const { labels: tasksLabels, tooltip } = getTasksTranslations(language);

  return (
    <FormContainer>
      <Card paddingTop positionRelative>
        <Form onSubmit={onSubmit}>
          <Header isMobile={isMobile}>
            <span className='header-title'>{labels.header}</span>
            <CloseIcon onClick={onCloseIconClick} customClass='x-icon' />
          </Header>

          <FormField noMargin>
            <StyledLabel required>{labels.title}:</StyledLabel>

            <TaskTitleContainer
              onClick={onTextFieldClick(TITLE)}
              isMobile={isMobile}
              title={!isMobile ? titles.editTitle : ""}
            >
              {!!title ? (
                <TaskTitle value={title} onChange={() => {}} />
              ) : (
                <Placeholder>{placeholders.title}...</Placeholder>
              )}
            </TaskTitleContainer>
          </FormField>

          <FormField>
            <StyledLabel>{label.assign}:</StyledLabel>

            {userOptions && (
              <Select
                // defaultValue={null}
                value={userAssigned}
                onChange={onAssignUserChange}
                options={userOptions}
                isSearchable
                isClearable
                placeholder={placeholder.user}
                noOptionsMessage={() => <span>{selectNoOptionsMessage}</span>}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    ...selectControlStyles(false),
                  }),
                }}
              />
            )}

            {isLoading && <ShimmerLoader />}
          </FormField>

          <FormField>
            <StyledLabel>{labels.comment}:</StyledLabel>

            <TaskComment comment={comment} onTextFieldClick={onTextFieldClick} create />
          </FormField>

          <FormField>
            <StyledLabel>{tasksLabels.deadline}:</StyledLabel>

            <DeadlineInputWrapper isValue={!!deadline}>
              <Input
                type='datetime-local'
                onChange={onDeadlineInputChange}
                value={deadline ?? undefined}
                customClass='deadline-input'
              />
            </DeadlineInputWrapper>
          </FormField>

          <FormField>
            <StyledLabel>{tasksLabels.attachments}:</StyledLabel>
            <AttachmentIcons taskId={id} tooltip={tooltip} />
          </FormField>

          <Actions>
            <ButtonContainer middle>
              <Button
                label={labels.button.create}
                disabled={disabled || createTaksLoading}
                loading={createTaksLoading}
                type='submit'
              />
            </ButtonContainer>
          </Actions>
        </Form>
      </Card>
    </FormContainer>
  );
}

export default CreateTaskForm;

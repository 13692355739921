import { useRef, useEffect, ChangeEvent, FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getCookie } from "react-use-cookie";
import { AxiosError } from "axios";

import { createTask, deleteTaskFiles } from "api/tasks";
import useModal from "hooks/useModal";
import useTimeout from "hooks/useTimeout";
import useCollection from "hooks/useCollection";
import useNotification from "hooks/useNotification";
import useTasksPhotosObjectStore from "hooks/useTasksPhotosObjectStore";
import { useAppSelector } from "redux/hooks/hooks";
import { Answers } from "types/types";
import {
  DeleteFilePayload,
  EditModalTypes,
  FilesCreate,
  NewTask,
} from "types/tasks.types";
import { UserOption } from "types/assign-to-user.types";
import {
  Create,
  CreateTaskPayload,
  Files,
  Related,
  TaskStatuses,
} from "types/tasks.types";
import { ReturnPathKeys } from "types/tasks.types";
import { RelatedCollection } from "types/tasks.types";
import { getTranslations as taskCreatorTranslations } from "./translations/task-creator.translations";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import {
  createNegativeAnswersList,
  getTaskDescription,
} from "./utils/task-creator.utils";
import { ACCESS_TOKEN } from "utils/utils";

import ConfirmationModal from "components/organisms/ConfirmationModal";
import CreateTaskForm from "./components/create-task-form/CreateTaskForm";
import ReactionModal from "components/molecules/reaction-modal/ReactionModal";

import { ContentContainer } from "styles/generalStyles";

type Response<T> = T | null;

enum AttachmentsTypes {
  PHOTOS = "photosToBeUploaded",
  DOCUMENTS = "documentsToBeUploaded",
}

const { TITLE, COMMENT } = EditModalTypes;
const { TO_DO } = TaskStatuses;
const { TASK_CREATOR } = ReturnPathKeys;
const { NO } = Answers;
const { ASSESSMENTS } = RelatedCollection;
const { PHOTOS, DOCUMENTS } = AttachmentsTypes;

const newTaskInitial: NewTask = {
  id: "",
  title: "",
  comment: "",
  selectedUser: null,
  deadline: null,
};

const getNewTaskFromStorage = () => {
  const localData = sessionStorage.getItem("newTask");

  return localData ? JSON.parse(localData) : newTaskInitial;
};

const checkIfHasAttachments = (attachmentType: AttachmentsTypes) => {
  const localData = sessionStorage.getItem(attachmentType);

  if (!localData) return false;

  return !!(JSON.parse(localData) as FilesCreate[]).length;
};

function TaskCreator() {
  const [newTask, setNewTask] = useState<NewTask>(getNewTaskFromStorage());
  const navigate = useNavigate();
  const { assessmentResponse } = useAppSelector(({ assesment }) => assesment);
  const currentEditModalRef = useRef<EditModalTypes>();
  const initialTitle = useRef<string>("");

  const notify = useNotification();
  const { collection, entityId } = useCollection();
  const setTimeOut = useTimeout();
  const {
    state: { answers, selectedQuestionnaire },
  } = useContext(QuestionnairesContext);
  const { deleteTaskPhotosInIndexedDB } = useTasksPhotosObjectStore();

  const {
    open: confirmationModalOpen,
    openModal: openConfirmationMopdal,
    closeModal: closeConfirmationModal,
    secondOpen: editModalOpen,
    openSecondModal: openEditModal,
    closeSecondModal: closeReactionModal,
  } = useModal();

  const token = getCookie(ACCESS_TOKEN);

  const {
    i18n: { language },
  } = useTranslation();

  const { labels, modal, alert } = taskCreatorTranslations(language);
  const isDirty = !!newTask.title || !!newTask.comment || !!newTask.selectedUser;
  const hasPhotosAttached = checkIfHasAttachments(PHOTOS);
  const hasDocumentsAttached = checkIfHasAttachments(DOCUMENTS);

  const disabled = !newTask.title;

  const modalType = currentEditModalRef.current;

  let editValue = "";

  if (modalType === TITLE) {
    editValue = newTask.title;
  }

  if (modalType === COMMENT) {
    editValue = newTask.comment;
  }

  let modalHeader = modalType ? modal.header[modalType] : "";

  // --------------- Reset state handlers ---------------

  const resetTextValues = () => {
    if (modalType === TITLE) {
      setNewTask((prev) => ({ ...prev, title: initialTitle.current }));
    }
  };

  const clearTaskPhotosInIndexedDB = () => {
    deleteTaskPhotosInIndexedDB();
  };

  const clearSessionStorage = () => {
    [
      "newTask",
      "documents",
      "photosToBeUploaded",
      "documentsToBeUploaded",
      TASK_CREATOR,
    ].forEach((item) => {
      sessionStorage.removeItem(item);
    });
  };

  // --------------- Navigate handler ---------------

  const navigateBack = () => {
    const path = sessionStorage.getItem(TASK_CREATOR) ?? "";

    navigate(path, { replace: true });
  };

  // --------------- Exit page handler ---------------

  const handleExitTaskCreator = () => {
    navigateBack();
    clearTaskPhotosInIndexedDB();
    clearSessionStorage();
  };

  // --------------- On success handler ---------------

  const onSuccess = () => {
    notify(alert.success, "success");
    setTimeOut(() => {
      handleExitTaskCreator();
    }, 1000);
    sessionStorage.removeItem("photosToBeUploaded");
  };

  // --------------- On error handler ---------------

  const onError = () => {
    notify(alert.error, "error");
  };

  // --------------- API handler ---------------

  const { mutate, isLoading: createTaksLoading } = useMutation<
    Response<null>,
    AxiosError,
    CreateTaskPayload
  >(createTask, {
    onSuccess,
    onError,
  });

  // --------------- Submit handler ---------------

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const Title = newTask.title;
    const Description = newTask.comment;
    const AssignTo = newTask.selectedUser ? newTask.selectedUser.id : null;
    const status = TO_DO;
    const AnswersLinkWithTask = assessmentResponse.answers;
    const Deadline = newTask.deadline;
    const photosCreateLocal = sessionStorage.getItem("photosToBeUploaded");
    const documentsCreateLocal = sessionStorage.getItem("documentsToBeUploaded");
    const assessmentId = assessmentResponse.assessment;

    let create: Create = [];

    if (collection) {
      create = [{ Tasks_id: "+", collection, item: { id: entityId } }];
    }

    if (assessmentId) {
      create = [
        ...create,
        {
          Tasks_id: "+",
          collection: ASSESSMENTS,
          item: { id: assessmentId },
        },
      ];
    }

    const Related: Related = {
      create,
    };

    const photosCreate: FilesCreate[] = photosCreateLocal
      ? JSON.parse(photosCreateLocal)
      : [];
    const documentsCreate: FilesCreate[] = documentsCreateLocal
      ? JSON.parse(documentsCreateLocal)
      : [];

    const filesCreate: FilesCreate[] = [...photosCreate, ...documentsCreate];

    const Files: Files = { create: filesCreate, delete: [] };

    const payload: CreateTaskPayload = {
      AnswersLinkWithTask,
      AssignTo,
      Deadline,
      Description,
      Files,
      Related,
      Title,
      status,
      token,
    };

    mutate(payload);
  };

  const { mutate: deleteFilesFromServer, isLoading: filesDeleting } = useMutation<
    null,
    AxiosError,
    DeleteFilePayload
  >(deleteTaskFiles, {
    onSuccess: () => {},
    onError: () => {},
    onSettled: () => {
      closeReactionModal();
      handleExitTaskCreator();
    },
  });

  const handleRemoveAttachments = () => {
    let attachmentsToBeDeleted: string[] = [];

    if (hasPhotosAttached) {
      const PhotosIds = [
        ...(JSON.parse(sessionStorage.getItem(PHOTOS) as string) as FilesCreate[]),
      ].map((item) => item.directus_files_id.id);

      attachmentsToBeDeleted = [...PhotosIds];
    }

    if (hasDocumentsAttached) {
      const DocumentsIds = [
        ...(JSON.parse(sessionStorage.getItem(DOCUMENTS) as string) as FilesCreate[]),
      ].map((item) => item.directus_files_id.id);

      attachmentsToBeDeleted = [...attachmentsToBeDeleted, ...DocumentsIds];
    }

    deleteFilesFromServer({ token, fileIds: attachmentsToBeDeleted });
  };

  // --------------- On click handlers ---------------

  const onCloseIconClick = () => {
    if (hasPhotosAttached || hasDocumentsAttached || isDirty) {
      openConfirmationMopdal();
    } else {
      handleExitTaskCreator();
    }
  };

  const onConfirmBtnClick = () => {
    if (hasPhotosAttached || hasDocumentsAttached) {
      handleRemoveAttachments();
    } else {
      handleExitTaskCreator();
    }
  };

  const onTextFieldClick = (modalType: EditModalTypes) => () => {
    currentEditModalRef.current = modalType;

    openEditModal();
  };

  const onCloseReactionModalIconClick = () => {
    resetTextValues();
    closeReactionModal();
  };

  const onSaveButtonClick = () => {
    initialTitle.current = newTask.title;

    closeReactionModal();
  };

  const onCloseConfirmModalIconClick = () => {
    if (filesDeleting) return;

    closeConfirmationModal();
  };

  // --------------- On change handlers ---------------

  const onAssignUserChange = (
    option: UserOption | null,
    { action }: ActionMeta<UserOption>,
  ) => {
    if (option) {
      const { id, value, label } = option;

      setNewTask((prev) => ({ ...prev, selectedUser: { id, label, value } }));
    }

    if (action === "clear") {
      setNewTask((prev) => ({ ...prev, selectedUser: null }));
    }
  };

  const modifyTextValues = (value: string) => {
    const { current: modalType } = currentEditModalRef;

    if (modalType === TITLE) {
      setNewTask((prev) => ({ ...prev, title: value }));
    }

    if (modalType === COMMENT) {
      setNewTask((prev) => ({ ...prev, comment: value }));
    }
  };

  const onEdititTextareaChange = (e: ChangeEvent<HTMLTextAreaElement> | string) => {
    if (typeof e === "string") {
      modifyTextValues(e);
    } else {
      modifyTextValues(e.target.value);
    }
  };

  const onTextTranscriptChange = (textTranscript: string) => {
    modifyTextValues(textTranscript);
  };

  const onDeadlineInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const dealineValue = inputValue ? inputValue : null; // Deadline value cannot be an empty string; it has to be null instead.

    setNewTask((prev) => ({ ...prev, deadline: dealineValue }));
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!newTask.id) {
      const id = `${Date.now()}`;
      setNewTask((prev) => ({ ...prev, id }));
    }

    sessionStorage.setItem("newTask", JSON.stringify(newTask));
  }, [newTask]);

  useEffect(() => {
    if (!selectedQuestionnaire) return;
    if (newTask.title) return;

    const { QuestionnaireTitle: Title } = selectedQuestionnaire;
    const negativeAnswers = answers.filter(({ Value }) => Value === NO);
    let negativeAnswersList = "";

    if (negativeAnswers.length) {
      negativeAnswersList = createNegativeAnswersList(negativeAnswers, labels.question);
    }

    const taskTitle = getTaskDescription(Title, negativeAnswersList, labels.negative);

    initialTitle.current = taskTitle;

    setNewTask((prev) => ({ ...prev, title: taskTitle }));
  }, [selectedQuestionnaire, answers, labels.question, labels.negative, newTask.title]);

  return (
    <ContentContainer>
      <CreateTaskForm
        newTask={newTask}
        disabled={disabled}
        onSubmit={onSubmit}
        onTextFieldClick={onTextFieldClick}
        createTaksLoading={createTaksLoading}
        onAssignUserChange={onAssignUserChange}
        onDeadlineInputChange={onDeadlineInputChange}
        onCloseIconClick={onCloseIconClick}
      />

      <ReactionModal
        header={modalHeader}
        open={editModalOpen}
        onClose={onCloseReactionModalIconClick}
        reactionTextAreaValue={editValue}
        onReactionTextAreaChange={onEdititTextareaChange}
        onTextTranscriptChange={onTextTranscriptChange}
        onSaveReactionBtnClick={onSaveButtonClick}
      />

      <ConfirmationModal
        open={confirmationModalOpen}
        message={filesDeleting ? modal.message.removingFiles : modal.message.question}
        onClose={onCloseConfirmModalIconClick}
        onClick={onConfirmBtnClick}
        buttonLabel={modal.button}
        loading={filesDeleting}
        closeDisabled={filesDeleting}
      />
    </ContentContainer>
  );
}

export default TaskCreator;

import { CountryCodes } from "types/types";
import { TaskStatuses } from "types/tasks.types";

const statusesEN = {
  todo: "To do",
  in_progress: "In progress",
  done: "Done",
  archived: "Archived",
};

const statusesPL = {
  todo: "Do zrobienia",
  in_progress: "W trakcie",
  done: "Zrobione",
  archived: "Zarchiwizowane",
};

export const getTaskStatusTranslation = (language: string, status: TaskStatuses) => {
  const isEnglish = language.includes(CountryCodes.EN);
  const isPolish = language.includes(CountryCodes.PL);
  let result = "";

  if (isEnglish) {
    result = statusesEN[status];
  }

  if (isPolish) {
    result = statusesPL[status];
  }

  return result;
};

import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";

import useQuestionnaire from "hooks/useQuestionnaire";
import useClickOutside from "hooks/useClickOutside";
import useAnimate from "hooks/useAnimate";
import useModal from "hooks/useModal";
import { CountryCodes } from "types/types";
import { isMobile } from "utils/utils";

import { ReactComponent as InfoIcon } from "assets/icons/info-circle.svg";

import { Question, QuestionContainer, SubText } from "../styles/questionnaireStyles";

const Container = styled.div<{ animate: boolean; duration: string }>`
  padding-top: 1rem;
  margin-bottom: 2rem;
  user-select: none;

  ${({ animate }) =>
    animate &&
    css<{ duration: string }>`
      animation-name: fade-in;
      animation-duration: ${({ duration }) => duration};
      animation-timing-function: ease-in;

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `}
`;

const Asterisk = styled.sup<{ infoVisible: boolean; isMobile: boolean }>`
  position: relative;
  display: inline-block;
  transform: translate(1px, 23px);
  color: crimson;
  font-size: 1.6em;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "help")};

  &::after {
    content: "*";
  }

  ${({ infoVisible }) =>
    infoVisible &&
    css`
      pointer-events: none;
    `}
`;

const InfoAlert = styled.div`
  min-width: 18rem;
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0.4rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.white};
  font-family: GothamBook;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .info-icon {
    width: 2rem;
    height: 2rem;
  }

  circle,
  line {
    stroke: ${({ theme }) => theme.white};
    stroke-width: 0.2rem;
  }
`;

const getTranslations = (language: string) =>
  language.includes(CountryCodes.PL) ? "Pytanie wymagane" : "Question required";

function QuestionTitle({ required }: { required: boolean }) {
  const ref = useRef<HTMLDivElement>(null);
  const { open, openModal, closeModal } = useModal();
  const { animate, duration } = useAnimate();
  const { currentQuestion } = useQuestionnaire();
  const timeoutId = useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));
  const {
    i18n: { language },
  } = useTranslation();

  const question = currentQuestion?.QuestionText as string;
  const subtext = currentQuestion?.QuestionSubtext as string;
  const label = getTranslations(language);

  useClickOutside(ref, closeModal);

  const onClick = () => {
    openModal();
  };

  useEffect(() => {
    if (open) {
      timeoutId.current = setTimeout(closeModal, 2000);
    } else {
      clearTimeout(timeoutId.current);
    }
  }, [open, closeModal]);

  return (
    <Container animate={animate} duration={`${duration}ms`}>
      <QuestionContainer>
        <Question>
          {question && question.trim()}
          {required && (
            <Asterisk onClick={onClick} infoVisible={open} isMobile={isMobile} />
          )}
          {open && (
            <InfoAlert ref={ref} onClick={closeModal}>
              <InfoIcon className='info-icon' />
              <span>{label}</span>
            </InfoAlert>
          )}
        </Question>
      </QuestionContainer>
      {!!subtext && <SubText dangerouslySetInnerHTML={{ __html: subtext }} />}
    </Container>
  );
}

export default QuestionTitle;

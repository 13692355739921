import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  clearLocalStorage,
  findAnswer,
  findAnswerIndex,
  getReturnPath,
  getSize,
} from "utils/utils";
import {
  AccidentFormQuestion,
  Answer,
  Answers,
  QUESTION_TYPE_IDS,
  QuestionType,
  FormTypes,
} from "types/types";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import usePhotosObjectStore from "./usePhotosObjectStore";

type AFQ = AccidentFormQuestion | undefined;
type QT = QuestionType | "";

const { FILE_PHOTO, NUMERIC, TEXT, YES_NO, YES_NO_TEXT, YES_NO_PHOTO, SELECT } =
  QUESTION_TYPE_IDS;
const { YES, NO } = Answers;
const { ACCIDENT } = FormTypes;

const initial = { id: "", value: "" };

function useAccidentForm() {
  const params = useParams();
  const navigate = useNavigate();

  const {
    state: { accidentAnswers, selectedAccidentForm },
    setAccidentAnswersAction,
    setSelectedAccidentFormAction,
  } = useContext(QuestionnairesContext);

  const [currentQuestion, setCurrentQuestion] = useState<AFQ>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);

  const [yesNoAnswer, setYesNoAnswer] = useState("");
  const [answerInputValue, setAnswerInputValue] = useState("");
  const [answerTextAreaValue, setAnswerTextAreaValue] = useState("");
  const [answerSelectValue, setAnswerSelectValue] = useState({ label: "", value: "" });

  const [reactionTextAreaValue, setReactionTextAreaValue] = useState("");
  const [reactionInCurrentAnswer, setReactionInCurrentAnswer] = useState(initial);
  const [reactionFileInCurrentAnswer, setReactionFileInCurrentAnswer] = useState(initial);

  const [cameraVisible, setCameraVisible] = useState(false);

  const [previewMode, setPreviewMode] = useState(false);

  const {
    getPhotosFromIndexedDB,
    updatePhotosInIndexedDB,
    deleteAllPhotosInIndexedDB,
    getNumberOfPhotos,
  } = usePhotosObjectStore(ACCIDENT);

  const numberOfQuestions = selectedAccidentForm?.Questions.length;
  const questionType: QT = currentQuestion?.QuestionType ?? "";

  const accidentFormId = selectedAccidentForm?.id;

  const answerToCurrentQuestion = findAnswer(accidentAnswers, currentQuestion?.id);
  const answerToCurrentQuestionIndex = findAnswerIndex(
    accidentAnswers,
    currentQuestion?.id,
  );

  const clearState = () => {
    setAccidentAnswersAction([]);
    setSelectedAccidentFormAction(undefined);
    clearLocalStorage(["accidentAnswers", "selectedAccidentForm"]);
    deleteAllPhotosInIndexedDB();
  };

  const navigateBack = () => {
    const returnPath = getReturnPath();
    const path = returnPath === "/home" ? "/home" : "/accident-forms-list";

    navigate(path, { replace: true });
  };

  const handleCloseForm = () => {
    clearState();
    navigateBack();
  };

  const clearInputFields = () => {
    if (answerInputValue) {
      setAnswerInputValue("");
    }
    if (answerTextAreaValue) {
      setAnswerTextAreaValue("");
    }
    if (reactionTextAreaValue) {
      setReactionTextAreaValue("");
    }
    if (reactionInCurrentAnswer) {
      setReactionInCurrentAnswer(initial);
    }
  };

  const goToNextQuestion = () => {
    if (numberOfQuestions) {
      if (currentQuestionIndex + 1 < numberOfQuestions) {
        setCurrentQuestionIndex((prev) => prev + 1);

        if (params.question) {
          const nextQuestionNumber = +params.question + 1;
          navigate(`/accident-form/${nextQuestionNumber}`);
        }
      } else {
        navigate(`/accident-form/summary/${accidentFormId}`);
      }
      clearInputFields();

      if (cameraVisible) {
        setCameraVisible(false);
      }
    }
  };

  const goToPreviousQuestion = () => {
    if (params.question) {
      const prevQuestionNumber = +params.question - 1;
      navigate(`/accident-form/${prevQuestionNumber}`);
    }
    setCurrentQuestionIndex((prev) => prev - 1);
  };

  // --------------- Answer creator ---------------

  const createAnswer = (value?: string) => {
    if (!questionType) return;
    if (!currentQuestion) return;

    const { id, sort, QuestionText, QuestionSubtext, QuestionType } = currentQuestion;
    let Value = null;
    let reaction = null;
    let FilesGallery = null;
    let Gravity = 0;

    if ("Gravity" in currentQuestion) {
      Gravity = currentQuestion.Gravity as unknown as number;
    }

    switch (questionType) {
      case TEXT:
        Value = answerTextAreaValue;
        break;

      case NUMERIC:
        Value = answerInputValue;
        break;

      case YES_NO:
        Value = value ? value : NO;

        if (previewMode) {
          setYesNoAnswer(Value);
          setReactionInCurrentAnswer({ id, value: "" });
        }
        break;

      case YES_NO_TEXT:
        Value = value ? value : NO;

        if (previewMode) {
          setYesNoAnswer(Value);
          setReactionInCurrentAnswer({ id, value: "" });

          if (Value === YES) {
            setReactionInCurrentAnswer({ id, value: "" });
          }
          if (Value === NO) {
            setReactionInCurrentAnswer({ id, value: reactionTextAreaValue });
            reaction = reactionTextAreaValue ? reactionTextAreaValue : null;
          }
        }
        break;

      case YES_NO_PHOTO:
        if (value === YES) {
          Value = YES;
        } else {
          Value = NO;

          if (value) {
            getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
              const filesGallery = photosFromDB ?? [];
              FilesGallery = [...filesGallery, { src: value, comment: "" }];

              updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
            });
          } else {
            getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
              updatePhotosInIndexedDB({
                Question: currentQuestion.id,
                FilesGallery: photosFromDB,
              });
            });
          }

          reaction = reactionTextAreaValue
            ? reactionTextAreaValue
            : (answerToCurrentQuestion?.ReactionValue as string);
        }

        if (previewMode) {
          setYesNoAnswer(Value);
        }
        break;

      case FILE_PHOTO:
        if (!answerToCurrentQuestion) {
          // Answer not created yet, this is the first photo to be added to FilesGallery
          FilesGallery = value ? [{ src: value, comment: "" }] : null;

          updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
        } else {
          // Answer already exists, update FilesGallery (add another photo)
          // If null, filesGallery is set to an empty array
          // to avoid "filesGallery is not iterable" error.

          if (value) {
            getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
              const filesGallery = photosFromDB ?? [];
              FilesGallery = [...filesGallery, { src: value, comment: "" }];

              updatePhotosInIndexedDB({ Question: currentQuestion.id, FilesGallery });
            });
          } else {
            getPhotosFromIndexedDB(currentQuestion.id).then((photosFromDB) => {
              updatePhotosInIndexedDB({
                Question: currentQuestion.id,
                FilesGallery: photosFromDB,
              });
            });
          }
        }

        reaction = reactionTextAreaValue
          ? reactionTextAreaValue
          : (answerToCurrentQuestion?.ReactionValue as string);
        break;

      case SELECT:
        Value = value as string;
    }
    const ReactionValue = reactionTextAreaValue ? reactionTextAreaValue : reaction;

    const ValueSize =
      getSize(Value) + getSize(FilesGallery?.map(({ src }) => src) as string[]);
    const ReactionSize =
      getSize(ReactionValue) + getSize(FilesGallery?.map(({ src }) => src) as string[]);

    const answer = {
      sort,
      Gravity,
      AnswerCreated: new Date(),
      OriginalText: QuestionText,
      OriginalSubText: QuestionSubtext,
      Value,
      ValueSize,
      ReactionValue,
      ReactionSize,
      Question: id,
      QuestionType,
      FilesGallery: null,
    } as unknown as Answer;

    let modifiedAnswers = [];

    // Modify answer that is already in state
    if (answerToCurrentQuestionIndex > -1) {
      modifiedAnswers = accidentAnswers;
      modifiedAnswers[answerToCurrentQuestionIndex] = answer;
    } else {
      // Add a new answer to answers array
      modifiedAnswers = [...accidentAnswers, answer];
    }

    setAccidentAnswersAction(modifiedAnswers);
  };

  // --------------- Answers updater ---------------

  const updateAnswersInState = (updatedAnswer: Answer) => {
    const modifiedAnswers = accidentAnswers;
    modifiedAnswers[answerToCurrentQuestionIndex] = updatedAnswer;

    setAccidentAnswersAction(modifiedAnswers);
  };

  // --------------- Remove handlers ---------------

  // Photo
  const removePhoto = (photoIndex: number) => {
    if (!answerToCurrentQuestion || !currentQuestion) {
      return;
    }

    const updatedFilesGallery = answerToCurrentQuestion.FilesGallery;
    updatedFilesGallery?.splice(photoIndex, 1);

    const updatedAnswer = {
      ...answerToCurrentQuestion,
      FilesGallery: updatedFilesGallery,
    };

    updateAnswersInState(updatedAnswer);
  };

  // Reaction
  const removeReactionComment = () => {
    if (!answerToCurrentQuestion || !currentQuestion) {
      return;
    }

    const updatedAnswer = {
      ...answerToCurrentQuestion,
      ReactionValue: null,
    };

    updateAnswersInState(updatedAnswer);
    setReactionInCurrentAnswer(initial);

    if (reactionTextAreaValue) {
      setReactionTextAreaValue("");
    }
  };

  // ----- Answer
  const removeAnswer = async (Question: string) => {
    const updatedAnswers = accidentAnswers.filter(
      (answer) => answer.Question !== Question,
    );

    setAccidentAnswersAction(updatedAnswers);
    setReactionInCurrentAnswer({ id: "", value: "" });

    if (questionType === YES_NO_PHOTO) {
      const containsPhotos = await !!getNumberOfPhotos(Question);

      if (containsPhotos) {
        updatePhotosInIndexedDB({ Question, FilesGallery: null });
      }
    }
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (params.question) {
      const index = +params.question - 1;

      setCurrentQuestionIndex(index);
    }
  }, [params]);

  useEffect(() => {
    if (selectedAccidentForm) {
      setCurrentQuestion(selectedAccidentForm.Questions[currentQuestionIndex]);
    }
  }, [currentQuestionIndex, selectedAccidentForm, accidentAnswers, currentQuestion?.id]);

  useEffect(() => {
    const isPreviewMode = accidentAnswers.some(
      (answer) => answer.Question === currentQuestion?.id,
    );

    setPreviewMode(isPreviewMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);

  return {
    accidentAnswers,
    createAnswer,
    removeAnswer,
    currentQuestion,
    currentQuestionIndex,
    questionType,
    numberOfQuestions,
    yesNoAnswer,
    setYesNoAnswer,
    answerInputValue,
    setAnswerInputValue,
    answerTextAreaValue,
    setAnswerTextAreaValue,
    answerSelectValue,
    setAnswerSelectValue,
    reactionTextAreaValue,
    setReactionTextAreaValue,
    reactionInCurrentAnswer,
    setReactionInCurrentAnswer,
    reactionFileInCurrentAnswer,
    setReactionFileInCurrentAnswer,
    cameraVisible,
    setCameraVisible,
    goToNextQuestion,
    goToPreviousQuestion,
    previewMode,
    clearInputFields,
    handleCloseForm,
    answerToCurrentQuestion,
    removePhoto,
    removeReactionComment,
    clearState,
  };
}

export default useAccidentForm;

const colors = {
  mainBackground: "#c8d6e5",
  greyLight: "#D6E0EC",
  greyDark: "#b3c6db",
  graphiteGrey: "#4B4E53",
  graphiteGreyLight: "#6c7077",
  jetGrey: "#616D7E",
  lightSlateGray: "#6D7B8D",
  pigeonPostGreyLight: "#b6c8dd",
  pigeonPostGrey: "#aabfd7",
  pigeonPostGreyDark: "#82a1c4",
  japaneseIndigo: "#2c3d4a",
  white: "#ffffff",
  aliceBlue: "#f0f8ff",
  glacier: "#7199c6",
  orange: "#ec9e54",
  babyYellow: "#FCEF91",
  deepBlue: "#3b28cc",
  royalBlue: "#3d7de4",
  // snapCheckBlue: "#212F5A",
  snapCheckBlueLight: "#4a5a7f",
  ceruleanBlue: "#069ae1",
  cobaltBlue: "#094db8",
  azure: "#007FFF",
  textColor1: "#ffffff",
  textColor2: "#000000",
  disabledColor: "#95afcc",
  successColor: "#23DB72",
  errorColor: "#b00020",
  warningColor: "#FFC107",
  activeColor: "#FDE05D",
  inactiveColor: "#718F9D",
  cancelColor: "#6C757D",
  yesButton: "#16aa2e",
  noButton: "#e2062c",
  ufoGreen: "#3CD070",
  malachiteGreen: "#64E986",
  emeraldGreen: "#50C878",
  mintGreen: "#98FB98",
  ruby: "#e0115f",
  primary_50: "#e8ebf1",
  primary_100: "#c4cdde",
  primary_200: "#9facc8",
  primary_300: "#7a8cb1",
  primary_400: "#5d73a2",
  primary_500: "#405b94",
  primary_600: "#3a538b",
  primary_700: "#31497f",
  primary_800: "#2b4072",
  primary: "#212f5a",
  secondary_50: "#fff3e1",
  secondary_100: "#ffe0b5",
  secondary_200: "#ffcb85",
  secondary_300: "#ffb656",
  secondary_400: "#ffa635",
  secondary: "#ff9721",
  secondary_600: "#fb8c1f",
  secondary_700: "#f47c1d",
  secondary_800: "#ed6d1c",
  secondary_900: "#e35419",
  listItemColor: "rgba(196, 205, 222, 0.55)",
  todo: "#3d7de4",
  inprogress: "#ffc23b",
  done: "#2ecda7",
  archived: "#a2b5cd",
};

const definedColors = {
  alarmColor: colors.secondary_900,
  buttonColorNeutral: colors.royalBlue,
};

const zIndex = {
  level1: "10",
  level2: "20",
  level3: "30",
  level4: "40",
  level5: "50",
  level6: "60",
  level7: "70",
  level8: "80",
  level9: "90",
  level10: "100",
};

export const dimensions = {
  topBarHeight: "6rem",
  topBarHeightLow: "5rem",
  inputButtonHeight: "5rem",
  inputButtonHeightLarge: "6rem",
  labelFonstSize: "2rem",
};

const stripeOne = colors.greyDark;
const stripeTwo = colors.pigeonPostGrey;

const mixins = {
  ellipsis: `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  ellipsisMultiline: `
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
`,
  checkListItem: `
    height: 11rem;
    background-color: ${colors.listItemColor};
    padding: 1.4rem 1.5rem;
    border-radius: 0.4rem;
    box-shadow: 1px 4px 9px -2px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 2rem;

    position: relative;

  `,
  shimmerEffect: `
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    );
    animation: shimmer 0.5s infinite linear;

    @keyframes shimmer {
      0% {
        transform: translateX(-300%);
      }
      100% {
        transform: translateX(300%);
      }
    }
  `,
  shake: `
    animation: shake 150ms 2 linear;

      @keyframes shake {
        0% {
          transform: translate(3px, 0);
        }
        50% {
          transform: translate(-3px, 0);
        }
        100% {
          transform: translate(0, 0);
        }
      }
  `,
  greyStripes: `
    background: repeating-linear-gradient(
      45deg,
      ${stripeOne},
      ${stripeOne} 10px,
      ${stripeTwo} 10px,
      ${stripeTwo} 20px
  );
  `,
  cardShadow: `
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
      0px 1px 3px 0px rgb(0 0 0 / 12%);
  `,
  fillUpRemainingSpace: `
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  scrollbarFirefox: `
    scrollbar-width: thin;
    scrollbar-color: ${colors.primary} transparent;
  `,
  iconBackground: `
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: grid;
    place-content: center;
  `,
  buttonShadow: `
    box-shadow: 0 1px 1px rgba(33, 47, 91, 0.25), 0 2px 8px rgba(33, 47, 91, 0.25),
      inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
  `,
  finder: `
    background: linear-gradient(to right, #212f5b 3px, transparent 3px) 0 0,
    linear-gradient(to right, #212f5b 3px, transparent 3px) 0 100%,
    l0inear-gradient(to left, #212f5b 3px, transparent 3px) 100% 0,
    linear-gradient(to left, #212f5b 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, #212f5b 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, #212f5b 3px, transparent 3px) 100% 0,
    linear-gradient(to top, #212f5b 3px, transparent 3px) 0 100%,
    linear-gradient(to top, #212f5b 3px, transparent 3px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
  `,
  rotate: `
    animation: rotate 1.5s linear infinite;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
   }
  `,
};

const theme = {
  ...colors,
  ...definedColors,
  ...dimensions,
  ...zIndex,
  ...mixins,
};

export default theme;

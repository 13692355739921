import { useEffect, useState } from "react";

function useBarcodeScannerAccess() {
  const [formats, setFormats] = useState<BarcodeFormat[]>([]);
  const hasAccessToBarcodeScanner = !!formats.length;

  useEffect(() => {
    if ("BarcodeDetector" in globalThis) {
      BarcodeDetector.getSupportedFormats().then((supportedFormats) => {
        setFormats([...supportedFormats]);
      });
    }
  }, []);

  return { hasAccessToBarcodeScanner, formats };
}

export default useBarcodeScannerAccess;

import { AccidentFormQuestion, Answer, QuestionObject } from "types/types";

export const shuffle = (array: QuestionObject[]) => {
  const newArray = [...array];
  return newArray.sort(() => Math.random() - 0.5);
};

export const getMinMaxPhotos = (
  currentQuestion: QuestionObject | AccidentFormQuestion | undefined,
) => {
  if (!currentQuestion)
    return {
      minPhotos: 0,
      maxPhotos: 0,
    };

  const { MaxPhotos, RequiredMinPhotos } = currentQuestion;
  const maxPhotos = MaxPhotos ?? 5;
  let minPhotos = RequiredMinPhotos ?? 0;

  if (minPhotos > maxPhotos) {
    minPhotos = maxPhotos;
  }

  return {
    minPhotos,
    maxPhotos,
  };
};

export const getQuestionId = (answer: Answer) =>
  answer?.Question ? answer?.Question : "";

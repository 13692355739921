import { ReactNode } from "react";

import { isMobile } from "utils/utils";

import { Container, Wrapper } from "../../styles/prompt.styles";

type PromptContainerProps = {
  onClick: () => void;
  children: ReactNode;
  animate: boolean;
  duration: number;
  className: string;
  shaking?: boolean;
};

function PromptContainer({
  children,
  onClick,
  animate,
  duration,
  className,
  shaking = false,
}: PromptContainerProps) {
  return (
    <Container animate={animate} duration={`${duration}ms`}>
      <Wrapper
        isMobile={isMobile}
        onClick={onClick}
        className={className}
        shaking={shaking}
      >
        {children}
      </Wrapper>
    </Container>
  );
}

export default PromptContainer;

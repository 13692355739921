import { useState } from "react";
import styled from "styled-components/macro";
import { IDetectedBarcode } from "@yudiel/react-qr-scanner";

import QrScanner from "../../organisms/qr-scanner/QrScanner";

const Container = styled.div`
  height: 27rem;
`;

function BarcodeScanner({ onScan }: { onScan: (result: IDetectedBarcode[]) => void }) {
  const [isCameraReady, setIsCameraReady] = useState(false);

  return (
    <Container>
      <QrScanner
        onScan={onScan}
        isCameraReady={isCameraReady}
        setIsCameraReady={setIsCameraReady}
        barcode
      />
    </Container>
  );
}

export default BarcodeScanner;

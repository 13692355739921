import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    minTextLength: "Minimum number of characters:",
    remaining: "Remaining:",
  };

  let error = {
    textTooLong: "Character limit exceeded!",
  };

  if (isPolish) {
    labels = {
      minTextLength: "Minimalna ilość znaków:",
      remaining: "Pozostało:",
    };

    error = {
      textTooLong: "Przekroczono limit znaków!",
    };
  }

  return { labels, error };
};

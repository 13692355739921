import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { QUESTION_TYPE_IDS, FormTypes, Answers } from "types/types";
import { PhotoPromptProps, PromptType } from "./types/prompt.types";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";
import useDevices from "hooks/useDevices";
import useAnimate from "hooks/useAnimate";
import { getPromptTranslations } from "./translations/prompt.translations";

import { ReactComponent as PhotoIcon } from "assets/icons/photo-camera-2.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

import PromptContainer from "./components/prompt-container/PromptContainer";

import {
  IconContainer,
  Label,
  PhotoCountContainer,
  MinPhotosWarning,
} from "./styles/prompt.styles";

const { FILE_PHOTO, YES_NO_PHOTO } = QUESTION_TYPE_IDS;
const { QUESTIONNAIRE, ACCIDENT } = FormTypes;
const { NO } = Answers;

function PhotoPrompt({
  onClick,
  questionType,
  currentQuestionId,
  minPhotos,
  maxPhotos,
  answerToCurrentQuestion,
  minPhotosNotReached = false,
}: PhotoPromptProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { animate, duration } = useAnimate();

  const { pathname } = useLocation();

  const formType = pathname.includes("/questionnaire") ? QUESTIONNAIRE : ACCIDENT;
  const { getNumberOfPhotos } = usePhotosObjectStore(formType);
  const { isVideoInput } = useDevices();

  const isPhotoType = questionType === FILE_PHOTO || questionType === YES_NO_PHOTO;

  const {
    i18n: { language },
  } = useTranslation();
  const { label, warning } = getPromptTranslations(PromptType.PHOTO, language);

  useEffect(() => {
    if (!currentQuestionId || !isVideoInput) return;

    if (
      !isPhotoType ||
      (questionType === YES_NO_PHOTO && answerToCurrentQuestion?.Value !== NO)
    ) {
      setIsVisible(false);
      return;
    }

    getNumberOfPhotos(currentQuestionId).then((containtPhotos) => {
      setIsVisible(!containtPhotos);
    });
  }, [
    getNumberOfPhotos,
    currentQuestionId,
    isPhotoType,
    answerToCurrentQuestion,
    questionType,
    isVideoInput,
  ]);

  return isVisible ? (
    <PromptContainer
      onClick={onClick}
      animate={animate}
      duration={duration}
      shaking={minPhotosNotReached}
      className='photo-prompt'
    >
      <PhotoCountContainer isRed={minPhotosNotReached}>
        min:&nbsp;{minPhotos}&nbsp;|&nbsp;max:&nbsp;{maxPhotos}
      </PhotoCountContainer>

      <IconContainer isRed={minPhotosNotReached}>
        <PhotoIcon className='icon' />
      </IconContainer>

      {!minPhotosNotReached ? (
        <Label>{label}</Label>
      ) : (
        <MinPhotosWarning>
          <WarningIcon className='warning-icon' />
          <span>
            {warning.minPhotos}&nbsp;{minPhotos}
          </span>
        </MinPhotosWarning>
      )}
    </PromptContainer>
  ) : null;
}

export default PhotoPrompt;

import styled, { css } from "styled-components/macro";
import { device } from "utils/utils";

export const Header = styled.div<{
  isMobile: boolean;
  marginTop?: boolean;
  noMarginBottom?: boolean;
}>`
  font-family: AuraAspect;
  text-align: center;
  position: relative;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 1rem;
    `}

  ${({ noMarginBottom }) =>
    !noMarginBottom &&
    css`
      margin-bottom: 1rem;
    `}

  .header-title {
    display: inline-block;
    max-width: 82%;
    font-size: 2rem;

    @media screen and (min-width: 360px) {
      font-size: 2.2rem;
    }

    @media screen and (min-width: 380px) {
      font-size: 2.4rem;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .back-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .x-icon {
    top: -1.3rem;
    right: -0.6rem;

    @media ${device.iPad} {
      top: -1rem;
    }
  }
`;

export const Placeholder = styled.span`
  opacity: 0.5;
  font-style: italic;
  font-size: 1.6rem;
  margin-top: 0.2rem;
`;

export const DeadlineInputWrapper = styled.div<{ isValue: boolean }>`
  .deadline-input {
    ${({ isValue }) =>
      !isValue &&
      css`
        color: hsl(0, 0%, 50%);
      `}
  }
`;

export const FormContainer = styled.div`
  margin-top: 1rem;
`;

export const Actions = styled.div<{ flexEnd?: boolean; center?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 5%;

  ${({ flexEnd }) =>
    flexEnd &&
    css`
      justify-content: flex-end;
    `}

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}
`;

export const CaptureButtonContainer = styled.div<{ documents?: boolean }>`
  flex-basis: 49%;

  ${({ documents }) =>
    documents &&
    css`
      flex-basis: 8%;
    `}
`;

export const CaptureBtn = styled.label<{
  isMobile: boolean;
  isLoading: boolean;
  documents?: boolean;
  large?: boolean;
}>`
  background-color: ${({ theme }) => theme.primary};
  padding: 0.75rem 0;
  display: block;
  border-radius: 0.4rem;
  color: #fff;
  text-align: center;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  user-select: none;

  .add-icon {
    width: 3.5rem;
    height: 3.5rem;

    transform: translate(0.3rem, 0.7rem);

    ${({ large }) =>
      large &&
      css`
        width: 5rem;
        height: 5rem;
        transform: translate(0, 1rem);
        opacity: 0.9;
      `}
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
      background-color: ${({ theme }) => theme.cancelColor};
    `}

  ${({ documents }) =>
    documents &&
    css`
      background-color: transparent;
      padding: 0;
      display: flex;
      justify-content: flex-end;
    `}

  &.capture {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

import styled, { css } from "styled-components/macro";

import { StyledTab } from "styles/tabs.styles";
import { MachineProcessStatuses } from "types/types";
import { device } from "utils/utils";

const { PUBLISHED, OUT_OF_ORDER } = MachineProcessStatuses;

type StatusColors = {
  [PUBLISHED]: string;
  [OUT_OF_ORDER]: string;
};

export const InfoList = styled.ul`
  margin-top: 1rem;
  ${({ theme }) => theme.fillUpRemainingSpace}
`;

export const InfoLine = styled.li<{ isMisc?: boolean }>`
  display: flex;
  overflow-y: hidden;
  position: relative;

  &:not(:last-child) {
    padding-bottom: 0.5rem;
  }

  ${({ isMisc }) =>
    isMisc &&
    css`
      overflow: auto;
      background-color: ${({ theme }) => theme.white};
      padding: 1rem;
      margin-top: 1rem;
      border-radius: 0.4rem;
      font-size: 1.8rem;

      min-height: 3rem;
      max-height: 18rem;

      @media screen and (min-height: 616px) {
        max-height: 27rem;
      }

      @media screen and (min-height: 702px) {
        max-height: 35rem;
      }

      @media screen and (min-height: 758px) {
        max-height: 40rem;
      }

      @media ${device.iPad} {
        max-height: 80rem;
      }
    `}
`;

export const Label = styled.span`
  flex-basis: 47%;
  padding-left: 1rem;
`;

export const Value = styled.span<{ isMisc?: boolean }>`
  flex-basis: 53%;
  font-family: GothamBold;

  ${({ isMisc }) =>
    isMisc &&
    css`
      flex-basis: 100%;
      font-family: GothamBook;
    `}
`;

export const Placeholder = styled.div`
  font-size: 1.6rem;
  font-style: italic;
  color: rgba(74, 90, 127, 0.75);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 1.2rem;
`;

export const TasksNumber = styled.span`
  display: inline-block;
  margin-left: 0.3rem;
  font-size: 1.4rem;

  transform: translateY(-0.1rem);

  &::before {
    content: "(";
  }

  &::after {
    content: ")";
  }
`;

export const StatusValue = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StatusDot = styled.div<{
  status: MachineProcessStatuses;
  statusColors: StatusColors;
}>`
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;

  ${({ status }) =>
    status === PUBLISHED &&
    css<{ statusColors: StatusColors }>`
      background-color: ${({ statusColors }) => statusColors[PUBLISHED]};
    `}

  ${({ status }) =>
    status === OUT_OF_ORDER &&
    css<{ statusColors: StatusColors }>`
      background-color: ${({ statusColors }) => statusColors[OUT_OF_ORDER]};
    `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace};
  height: calc(100% - 6.5rem);
`;

export const Actions = styled.div`
  padding-bottom: 1rem;
  margin-top: 1rem;
  flex-basis: 5%;
  display: flex;
  justify-content: flex-end;

  @media ${device.iPad} {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .button {
    width: 12rem;
  }
`;

export const Loader = styled(StyledTab)`
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.cardShadow};
`;

export const Shimmer = styled.div`
  ${({ theme }) => theme.shimmerEffect}
`;

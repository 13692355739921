import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { Swiper as SwiperType } from "swiper";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getCookie } from "react-use-cookie";
import { useMutation } from "react-query";
import { AxiosError, AxiosProgressEvent } from "axios";

import { ACCESS_TOKEN, isMobile } from "utils/utils";
import useModal from "hooks/useModal";
import useTasksPhotosObjectStore from "hooks/useTasksPhotosObjectStore";
import useNotification from "hooks/useNotification";
import useDevices from "hooks/useDevices";
import { deleteTaskFiles, uploadTaskFile } from "api/tasks";
import {
  DeleteFilePayload,
  PhotoGallery,
  UploadFilePayload,
  UploadFileResponse,
} from "types/tasks.types";
import { getTranslations } from "pages/user-tasks/translations/tasks.translations";
import { createFileObjectForPayload } from "pages/task-creator/utils/task-creator.utils";
import { baseURL } from "axios-instance/axios-instance";
import { ImageSize } from "types/types";

import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as FullScreenIcon } from "assets/icons/fullscreen.svg";

import BackArrow from "components/atoms/BackArrow";
import PhotoLoader from "./components/photo-loader/PhotoLoader";
import PreviewImage from "./components/preview-image/PreviewImage";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import LastSlide from "./components/last-slide/LastSlide";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { ContentContainer } from "styles/generalStyles";
import { Header, Actions } from "pages/task-creator/styles/task-creator.styles";
import {
  BinContainer,
  Container,
  GalleryContainer,
  PhotoCounter,
  FullScreenIconContainer,
  Done,
} from "./styles/task-photos.styles";

const lastSlideSizeInitial = { width: 0, height: 0 };
const LAST_SLIDE_SIZE = "lastSlideSize";

const getLastSlideSizeFromStorage = () => {
  const localData = sessionStorage.getItem(LAST_SLIDE_SIZE);

  return localData ? JSON.parse(localData) : lastSlideSizeInitial;
};

function TaskPhotos() {
  const [photos, setPhotos] = useState<PhotoGallery>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType>();
  const [lastSlideSize, setLastSlideSize] = useState<ImageSize>(
    getLastSlideSizeFromStorage(),
  );
  const [searchParams] = useSearchParams();

  const currentTaskId = searchParams.get("taskId");

  const {
    setUpTasksPhotosObjectStore,
    getTaskPhotosFromIndexedDB,
    updateTaskPhotosInIndexedDB,
  } = useTasksPhotosObjectStore();

  const notify = useNotification();
  const navigate = useNavigate();
  const token = getCookie(ACCESS_TOKEN);

  const { open, openModal, closeModal } = useModal();
  const { isVideoInput } = useDevices();

  const taskIdRef = useRef("");
  const photoToBeUploadedRef = useRef<File>();
  const updatedPhotoGalleryRef = useRef<PhotoGallery>([]);
  const setUpTasksPhotosObjectStoreRef = useRef(setUpTasksPhotosObjectStore);
  const getTaskPhotosFromIndexedDBRef = useRef(getTaskPhotosFromIndexedDB);

  const canTakePhotos = isMobile && isVideoInput;
  const isFirstPhoto = currentIndex === 0;
  const isLastSlide = currentIndex === photos.length;

  const {
    i18n: { language },
  } = useTranslation();

  const { alert, header, labels, modal } = getTranslations(language);

  // --------------- Photo handlers ---------------

  const onSuccess = ({ data }: UploadFileResponse) => {
    const { current: id } = taskIdRef;
    const file = photoToBeUploadedRef.current as File;
    const photoId = data.id;

    getTaskPhotosFromIndexedDB(id).then((photosFromDB) => {
      const photoGallery = photosFromDB ?? [];
      const PhotoGallery: PhotoGallery = [{ file, photoId }, ...photoGallery];

      updateTaskPhotosInIndexedDB({ id, PhotoGallery });
    });

    setPhotos((prev) => [{ file, photoId }, ...prev]);
    swiperInstance?.slideTo(0, 500);
    photoToBeUploadedRef.current = undefined;
    setCurrentIndex(0);
    notify(alert.success.photoAttached, "success");
  };

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const value = Math.ceil((progressEvent.progress as number) * 100);

    setUploadProgress(value);
  };

  // --------------- Mutations ---------------

  const { mutate: uploadPhoto, isLoading: photoUploading } = useMutation<
    UploadFileResponse,
    AxiosError,
    UploadFilePayload
  >(uploadTaskFile, {
    onSuccess,
    onError: () => {
      notify(alert.error.failedToAttachPhoto, "error");
    },
  });

  const { mutate: deletePhotoFromServer, isLoading: photoDeleting } = useMutation<
    null,
    AxiosError,
    DeleteFilePayload
  >(deleteTaskFiles, {
    onSuccess: () => {
      notify(alert.success.photoDeleted, "success");
      updateTaskPhotosInIndexedDB({
        PhotoGallery: updatedPhotoGalleryRef.current,
        id: taskIdRef.current,
      });
      setPhotos(updatedPhotoGalleryRef.current);
      closeModal();
      updatedPhotoGalleryRef.current = [];
    },
    onError: () => {
      notify(alert.error.failedToDletePhoto, "error");
    },
  });

  // ---------------

  const handleAddPhoto = (photo: File) => {
    photoToBeUploadedRef.current = photo;

    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("photoName", photo.name);

    const payload: UploadFilePayload = {
      token,
      formData,
      onUploadProgress,
    };

    uploadPhoto(payload);
  };

  const handleDeletePhoto = () => {
    updatedPhotoGalleryRef.current = [...photos];

    const arrayAfterRemovingPhoto = updatedPhotoGalleryRef.current.splice(
      currentIndex,
      1,
    );
    const fileIds = [arrayAfterRemovingPhoto[0].photoId];

    deletePhotoFromServer({ token, fileIds });
  };

  const getHref = () => {
    if (isLastSlide) return;

    const { photoId } = photos[currentIndex];

    return `${baseURL}assets/${photoId}?access_token=${token}`;
  };

  const handleLastSlideSize = (imgSize: ImageSize) => {
    const sizeUnset = Object.values(lastSlideSize).some((size) => !size);

    if (sizeUnset) {
      setLastSlideSize(imgSize);
    }
  };

  // --------------- On change handlers ---------------

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const photo = e.target.files[0];

      if (photo && photo.type.includes("image")) {
        handleAddPhoto(photo);
      } else {
        notify(alert.warning.notPhoto, "warning");
      }
    }
  };

  const onSlideChange = ({ activeIndex }: SwiperType) => {
    setCurrentIndex(activeIndex);
  };

  // --------------- On click handlers ---------------

  const onBackArrowClick = () => {
    if (photoDeleting) return;
    navigate("/task-creator", { replace: true });
    sessionStorage.removeItem(LAST_SLIDE_SIZE);
  };

  const onBinIconClick = () => {
    openModal();
  };

  const onConfirmButtonClick = () => {
    handleDeletePhoto();
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (currentTaskId) {
      taskIdRef.current = currentTaskId;
      setUpTasksPhotosObjectStoreRef.current(currentTaskId);

      getTaskPhotosFromIndexedDBRef.current(currentTaskId).then((photosFromDB) => {
        if (photosFromDB) {
          setPhotos(photosFromDB);
        }
      });
    }
  }, [currentTaskId]);

  useEffect(() => {
    const photosToBeUploaded = photos.map(({ photoId: id }) =>
      createFileObjectForPayload(id),
    );

    sessionStorage.setItem("photosToBeUploaded", JSON.stringify(photosToBeUploaded));
  }, [photos]);

  useEffect(() => {
    if (lastSlideSize.width && lastSlideSize.height) {
      sessionStorage.setItem(LAST_SLIDE_SIZE, JSON.stringify(lastSlideSize));
    }
  }, [lastSlideSize]);

  return (
    <ContentContainer>
      <Container>
        <Header isMobile={isMobile} marginTop>
          {!photos.length && (
            <BackArrow onClick={onBackArrowClick} customClass='move-arrow' />
          )}
          <span className='header-title'>{header.photos}</span>
        </Header>

        <GalleryContainer
          isMobile={isMobile}
          isLeftArrowHidden={isFirstPhoto}
          isRightArrowHidden={isLastSlide}
        >
          {!!photos.length && !photoUploading && (
            <Swiper
              pagination={{
                type: "progressbar",
              }}
              navigation
              rewind
              modules={[Pagination, Navigation]}
              onSlideChange={onSlideChange}
              spaceBetween={10}
              initialSlide={0}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
              {photos.map((photo) => {
                const src = URL.createObjectURL(photo.file);

                return (
                  <SwiperSlide key={`${photo.photoId}`}>
                    <PreviewImage src={src} handleLastSlideSize={handleLastSlideSize} />

                    <FullScreenIconContainer
                      isMobile={isMobile}
                      href={getHref()}
                      rel='noreferrer'
                    >
                      <FullScreenIcon className='fullscreen-icon' />
                    </FullScreenIconContainer>
                  </SwiperSlide>
                );
              })}

              <SwiperSlide>
                <LastSlide
                  size={lastSlideSize}
                  onChange={onChange}
                  photoUploading={photoUploading}
                  canTakePhotos={canTakePhotos}
                  labels={{
                    takePhoto: labels.btn.takeAnother,
                    selectExisting: labels.btn.selectExisting,
                  }}
                />
              </SwiperSlide>

              {!!photos.length && !isLastSlide && (
                <PhotoCounter>
                  {currentIndex + 1}&nbsp;/&nbsp;
                  {photos.length}
                </PhotoCounter>
              )}

              {!isLastSlide && (
                <BinContainer isMobile={isMobile} onClick={onBinIconClick}>
                  <BinIcon className='bin' />
                </BinContainer>
              )}
            </Swiper>
          )}
          {!photos.length && !photoUploading && (
            <LastSlide
              size={lastSlideSize}
              onChange={onChange}
              photoUploading={photoUploading}
              canTakePhotos={canTakePhotos}
              labels={{
                takePhoto: labels.btn.takePoto,
                selectExisting: labels.btn.selectExisting,
              }}
            />
          )}

          {photoUploading && (
            <PhotoLoader
              progress={uploadProgress}
              photoFile={photoToBeUploadedRef.current}
            />
          )}
        </GalleryContainer>

        <Actions flexEnd={!canTakePhotos || (canTakePhotos && !!photos.length)}>
          {!!photos.length && (
            <Done
              isMobile={isMobile}
              onClick={onBackArrowClick}
              role='button'
              disabled={photoUploading || photoDeleting}
            >
              {labels.btn.done}
            </Done>
          )}
        </Actions>
      </Container>

      <ConfirmationModal
        message={modal.message.photo}
        onClick={onConfirmButtonClick}
        onClose={closeModal}
        open={open}
        buttonLabel={labels.btn.delete}
        loading={photoDeleting}
        closeDisabled={photoDeleting}
      />
    </ContentContainer>
  );
}

export default TaskPhotos;

import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    redirecting: "Redirecting to questionnaire...",
  };

  let error = {
    redirect: "Redirection error - QR code contains invalid questionnaire ID...",
  };

  if (isPolish) {
    labels = {
      redirecting: "Przekierowuję do kwestionariusza...",
    };

    error = {
      redirect:
        "Błąd przekierowania - kod QR zawiera błędny identyfikator kwestionariusza...",
    };
  }

  return { labels, error };
};

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { useLocation, useNavigate } from "react-router-dom";

import { device } from "utils/utils";

import stopSign from "assets/icons/stop-sign-48.png";

import Button from "components/atoms/Button";

import { ModalStyles } from "styles/generalStyles";
import { ActionsWrapper } from "../styles/summaryStyles";
import { CountryCodes } from "types/types";

const Container = styled.div`
  margin-top: 1.5rem;
  margin-left: 1rem;
  ${({ theme }) => theme.fillUpRemainingSpace}
`;

const Info = styled.div`
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.primary};

  .summary-button {
    margin-top: 1.5rem;
    height: 4rem;
  }

  @media screen and (min-height: 616px) {
    margin-bottom: 3rem;
  }

  @media ${device.iPad} {
    font-size: 1.8rem;
  }
`;

const InfoMessage = styled.div`
  font-family: GothamBold;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
  font-size: 1.6rem;
`;

const InfoMessageLine = styled.span<{ bulletVisible: boolean }>`
  ${({ bulletVisible }) =>
    bulletVisible &&
    css`
      &::before {
        content: "• ";
      }
    `}
`;

const StopSign = styled.img`
  width: 6rem;
  height: 6rem;
`;

const { Actions, ButtonContainer } = ModalStyles;

type ReportIncompleteInfoProps = {
  onClick: () => void;
  openModal: () => void;
  allRequiredQuestionsAnswered: boolean;
  answersWithMissingPhotosNumber: number;
  answersWithMissingDescriptionNumber: number;
};

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let message = {
    required: "Not all required questions have been answered.",
    photos: "There are some answers with missing photos.",
    description: "There are some answers with missing description.",
  };

  let button = {
    complete: "Complete",
    summary: "See summary",
  };

  if (isPolish) {
    message = {
      required: "Nie udzielono odpowiedzi na wszystkie wymagane pytania.",
      photos: "W niektórych odpowiedziach brakuje zdjęć.",
      description: "W niektórych odpowiedziach brakuje opisu.",
    };

    button = {
      complete: "Uzupełnij",
      summary: "Zobacz podsumowanie",
    };
  }

  return { message, button };
};

function ReportIncompleteInfo({
  onClick,
  openModal,
  allRequiredQuestionsAnswered,
  answersWithMissingPhotosNumber,
  answersWithMissingDescriptionNumber,
}: ReportIncompleteInfoProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation("summary");
  const { message, button } = getTranslations(language);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const visible =
    !allRequiredQuestionsAnswered ||
    !!answersWithMissingPhotosNumber ||
    !!answersWithMissingDescriptionNumber;
  const bulletVisible =
    (!allRequiredQuestionsAnswered && !!answersWithMissingPhotosNumber) ||
    (!allRequiredQuestionsAnswered && !!answersWithMissingDescriptionNumber) ||
    (!!answersWithMissingPhotosNumber && !!answersWithMissingDescriptionNumber);

  const onSummaryBtnClick = () => {
    const currentPath = pathname + search;

    localStorage.setItem("tableOfContentsReturnPath", currentPath);
    navigate("/table-of-contents");
  };

  return visible ? (
    <Container>
      <Info>
        <InfoMessage>
          <StopSign src={stopSign} alt='' />
          {!allRequiredQuestionsAnswered && (
            <InfoMessageLine bulletVisible={bulletVisible}>
              {message.required}
            </InfoMessageLine>
          )}

          {!!answersWithMissingPhotosNumber && (
            <InfoMessageLine bulletVisible={bulletVisible}>
              {message.photos}
            </InfoMessageLine>
          )}

          {!!answersWithMissingDescriptionNumber && (
            <InfoMessageLine bulletVisible={bulletVisible}>
              {message.description}
            </InfoMessageLine>
          )}
        </InfoMessage>

        <Button
          label={button.summary}
          customClass='summary-button'
          onClick={onSummaryBtnClick}
        />
      </Info>

      <ActionsWrapper>
        <Actions spaceBetween>
          <ButtonContainer double>
            <Button label={t("button.discard")} question onClick={openModal} />
          </ButtonContainer>

          <ButtonContainer middle>
            <Button onClick={onClick} label={button.complete} />
          </ButtonContainer>
        </Actions>
      </ActionsWrapper>
    </Container>
  ) : null;
}

export default ReportIncompleteInfo;

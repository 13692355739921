import styled, { css } from "styled-components/macro";

export const Container = styled.div<{ animate: boolean; duration: string }>`
  position: relative;
  height: 9.4rem;
  margin-bottom: 1rem;

  ${({ animate }) =>
    animate &&
    css<{ duration: string }>`
      animation-name: fade-in;
      animation-duration: ${({ duration }) => duration};
      animation-timing-function: ease-in;

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `}
`;

export const Wrapper = styled.div<{ isMobile: boolean; shaking: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  background-color: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.primary_200};
  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  ${({ shaking }) =>
    shaking &&
    css`
      border-color: ${({ theme }) => theme.errorColor};
      background-color: #fffbfc;
      ${({ theme }) => theme.shake};
    `};
`;

export const IconContainer = styled.div<{ isRed?: boolean }>`
  width: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 100%;
    height: 100%;
    transform: translateY(1.3rem);

    path {
      fill: ${({ theme }) => theme.primary_500};
    }
  }

  .icon {
    ${({ isRed }) =>
      isRed &&
      css`
        path {
          fill: ${({ theme }) => theme.errorColor};
        }
      `}
  }
`;

export const Label = styled.div<{ isRed?: boolean }>`
  color: ${({ theme }) => theme.primary_500};
  text-align: center;
  font-family: GothamBold;
  font-size: 1.8rem;
  padding: 1rem 0;
  line-height: 1.8rem;

  ${({ isRed }) =>
    isRed &&
    css`
      color: ${({ theme }) => theme.errorColor};
    `}
`;

export const MinPhotosWarning = styled(Label)`
  color: ${({ theme }) => theme.errorColor};
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  margin-top: 0.7rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .warning-icon {
    width: 1.8rem;
    height: 1.8rem;
    display: none;
    fill: ${({ theme }) => theme.errorColor};

    @media screen and (min-width: 340px) {
      display: block;
    }
  }
`;

export const PhotoCountContainer = styled.div<{ isRed: boolean }>`
  position: absolute;
  left: 1rem;
  top: 1rem;
  font-size: 1.4rem;
  font-family: GothamBold;
  color: ${({ theme }) => theme.primary_400};

  ${({ isRed }) =>
    isRed &&
    css`
      color: ${({ theme }) => theme.errorColor};
    `}
`;

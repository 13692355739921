import {
  Answer,
  Answers,
  FormTypes,
  QuestionnaireObject,
  QUESTION_TYPE_IDS,
  FilesGalleryRecord,
  QuestionObject,
} from "types/types";
import usePhotosForAnswers from "./usePhotosForAnswers";
import { getMinMaxPhotos } from "pages/questionnaire/utils/utils";

const { QUESTIONNAIRE } = FormTypes;
const { FILE_PHOTO, YES_NO_PHOTO, YES_NO_TEXT } = QUESTION_TYPE_IDS;
const { NO } = Answers;

const getRequiredQuestions = ({ Questions }: QuestionnaireObject) =>
  Questions.filter(({ Required }) => !!Required);

// ----- Answers with missing photos handlers -----

const getAnswersWithPhotos = (answer: Answer) =>
  answer.QuestionType === FILE_PHOTO ||
  (answer.QuestionType === YES_NO_PHOTO && answer.Value === NO)
    ? answer
    : undefined;

const getAnswersWithMissingPhotos = (
  questionnaire: QuestionnaireObject,
  answers: Answer[],
  photosForAnswers: FilesGalleryRecord | undefined,
) =>
  !!photosForAnswers
    ? answers
        .filter(getAnswersWithPhotos)
        .filter((answer) => !!answer)
        .reduce((acc: Answer[], curr) => {
          const { Questions } = questionnaire;
          const currentQuestion = Questions.find(({ id }) => id === curr.Question);
          const { minPhotos } = getMinMaxPhotos(currentQuestion);
          const photosTaken =
            photosForAnswers && photosForAnswers[curr.Question]
              ? photosForAnswers[curr.Question].length
              : 0;

          if (minPhotos > photosTaken) {
            acc = [...acc, curr];
          }

          return acc;
        }, [])
    : [];

// ----- Answers with missing description handlers -----

const getAnswersWithMissingDescription = (
  answers: Answer[],
  requiredQuestions: QuestionObject[],
) =>
  answers.filter(
    ({ QuestionType, ReactionValue, Value, Question }) =>
      QuestionType === YES_NO_TEXT &&
      Value === NO &&
      requiredQuestions.some(({ id }) => id === Question) &&
      !ReactionValue,
  );

// --------------- ***** ---------------

function useRequiredQuestions(answers: Answer[], questionnaire: QuestionnaireObject) {
  const requiredQuestions = getRequiredQuestions(questionnaire);
  const { photosForAnswers } = usePhotosForAnswers(QUESTIONNAIRE);

  // ***** Required questions *****

  let allRequiredQuestionsAnswered = true;
  let requiredQuestionsUnanswered: string[] = [];
  let firstUnansweredQuestionIndex = 0;

  if (requiredQuestions.length) {
    const requiredQuestionsAnswered = requiredQuestions.reduce((acc: string[], curr) => {
      if (answers.some(({ Question }) => Question === curr.id)) {
        acc = [...acc, curr.id];
      }

      return acc;
    }, []);

    requiredQuestionsUnanswered = requiredQuestions.reduce((acc: string[], curr) => {
      if (!requiredQuestionsAnswered.some((id) => id === curr.id)) {
        acc = [...acc, curr.id];
      }

      return acc;
    }, []);

    firstUnansweredQuestionIndex = questionnaire.Questions.findIndex(
      ({ id }) => id === requiredQuestionsUnanswered[0],
    );

    allRequiredQuestionsAnswered = !requiredQuestionsUnanswered.length;
  }

  // ***** Answers with missing photos *****

  let firstQuestionWithMissingPhotosIndex = 0;

  const answersWithMissingPhotos = getAnswersWithMissingPhotos(
    questionnaire,
    answers,
    photosForAnswers,
  );

  const answersWithMissingPhotosNumber = answersWithMissingPhotos.length;

  if (answersWithMissingPhotosNumber) {
    firstQuestionWithMissingPhotosIndex = questionnaire.Questions.findIndex(
      ({ id }) => id === answersWithMissingPhotos[0].Question,
    );
  }

  // ***** Answers with missing description *****

  let firstQuestionWithMissingDescriptionIndex = 0;

  const answersWithMissingDescription = getAnswersWithMissingDescription(
    answers,
    requiredQuestions,
  );
  const answersWithMissingDescriptionNumber = answersWithMissingDescription.length;

  if (answersWithMissingDescriptionNumber) {
    firstQuestionWithMissingDescriptionIndex = questionnaire.Questions.findIndex(
      ({ id }) => id === answersWithMissingDescription[0].Question,
    );
  }

  const indexes = [
    firstUnansweredQuestionIndex,
    firstQuestionWithMissingPhotosIndex,
    firstQuestionWithMissingDescriptionIndex,
  ].filter((index) => index > 0);

  const goToIndex = Math.min(...indexes);

  return {
    allRequiredQuestionsAnswered,
    answersWithMissingPhotosNumber,
    answersWithMissingDescriptionNumber,
    goToIndex,
  };
}

export default useRequiredQuestions;

import { useContext } from "react";

import {
  Stores,
  FormType,
  FormTypes,
  Af,
  SelectedQuestionnaire,
  FilesGallery,
  PhotoObjectStoreItem,
} from "types/types";
import useIndexedDB from "./useIndexedDB";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import { checkIfPhotoType } from "utils/utils";

const { PhotosQuestionnaire, PhotosAccident } = Stores;

const { QUESTIONNAIRE, ACCIDENT } = FormTypes;

function usePhotosObjectStore(formType: FormType) {
  const {
    state: { selectedQuestionnaire, selectedAccidentForm },
  } = useContext(QuestionnairesContext);

  let store: Stores;
  let selectedForm: SelectedQuestionnaire | Af;

  switch (formType) {
    case QUESTIONNAIRE:
      store = PhotosQuestionnaire;
      selectedForm = selectedQuestionnaire;
      break;
    case ACCIDENT:
      store = PhotosAccident;
      selectedForm = selectedAccidentForm;
      break;
  }

  const { addItem, getItem, getAllItems, updateItem, deleteAll } = useIndexedDB(store);

  const setUpPhotosObjectStore = async () => {
    if (!selectedForm) return;

    const photoTypeQuestions = selectedForm.Questions.filter(checkIfPhotoType);

    photoTypeQuestions.forEach(async (question) => {
      await addItem({ Question: question.id, FilesGallery: null });
    });
  };

  const getPhotosFromIndexedDB = async (questionId: string) => {
    const { FilesGallery } = (await getItem(questionId)) as PhotoObjectStoreItem;

    return FilesGallery;
  };

  const getAllPhotosFromIndexedDB = async () => {
    const allPhotos = (await getAllItems(store)) as PhotoObjectStoreItem[];

    return new Promise((resolve) => {
      resolve(allPhotos);
    });
  };

  const updatePhotosInIndexedDB = (payload: {
    FilesGallery: FilesGallery | null;
    Question: string;
  }) => {
    updateItem(payload);
  };

  const deleteAllPhotosInIndexedDB = () => {
    deleteAll();
  };

  const getNumberOfPhotos = async (questionId: string) => {
    const photoItem = (await getItem(questionId)) as PhotoObjectStoreItem;
    const numberOfPhotos =
      !!photoItem && !!photoItem.FilesGallery ? photoItem.FilesGallery.length : 0;

    return new Promise((resolve) => {
      resolve(numberOfPhotos);
    });
  };

  return {
    setUpPhotosObjectStore,
    getPhotosFromIndexedDB,
    updatePhotosInIndexedDB,
    getAllPhotosFromIndexedDB,
    deleteAllPhotosInIndexedDB,
    getNumberOfPhotos,
  };
}

export default usePhotosObjectStore;

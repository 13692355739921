import { ChangeEvent, useEffect, useState, useRef } from "react";
import { AxiosError, AxiosProgressEvent } from "axios";
import { useTranslation } from "react-i18next";
import { getCookie } from "react-use-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";

import { deleteTaskFiles, uploadTaskFile } from "api/tasks";
import useModal from "hooks/useModal";
import useNotification from "hooks/useNotification";
import { FileTypes } from "hooks/useTaskAttachments";
import { ACCESS_TOKEN, createHref, isMobile } from "utils/utils";
import { createFileObjectForPayload } from "pages/task-creator/utils/task-creator.utils";
import { getTranslations } from "pages/user-tasks/translations/tasks.translations";
import {
  CreatedTask,
  DeleteFilePayload,
  FileType,
  UploadFilePayload,
  UploadFileResponse,
} from "types/tasks.types";

import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as AddIcon } from "assets/icons/add-4.svg";

import FileIcon from "components/molecules/file-icon/FileIcon";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import UploadProgressBar from "components/atoms/upload-progress-bar/UploadProgressBar";

import { ContentContainer } from "styles/generalStyles";
import {
  Header,
  Actions,
  CaptureBtn,
  CaptureButtonContainer,
} from "pages/task-creator/styles/task-creator.styles";
import {
  Container,
  Done,
  Placeholder,
} from "pages/task-photos/styles/task-photos.styles";
import { FileLink, FileName } from "styles/files.styles";
import {
  DocumentItem,
  DocumentsContainer,
  DocumentsList,
  ProgressContainer,
  UploadOverlay,
  BinContainer,
} from "./styles/task-documents.styles";

type Document = { id: string; title: string; type: FileType };

const getLocalData = (isSelectedTask: boolean) => {
  const ITEM = isSelectedTask ? "selectedTask" : "documents";
  const localData = sessionStorage.getItem(ITEM);

  let documents = [];

  if (localData) {
    if (isSelectedTask) {
      const { Files } = JSON.parse(localData) as CreatedTask;

      documents = Files.filter((item) =>
        item.directus_files_id?.type.includes(FileTypes.DOCUMENT),
      )
        .map(({ directus_files_id }) => {
          if (directus_files_id) {
            const { id, title, type: fileType } = directus_files_id;
            const type = fileType.split("/")[1];

            return { id, title, type };
          }

          return undefined;
        })
        .filter((item) => !!item);
    } else {
      documents = JSON.parse(localData);
    }
  }

  return documents;
};

function TaskDocuments() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isSelectedTask = pathname.includes("/task/selected");

  const [documents, setDocuments] = useState<Document[]>(getLocalData(isSelectedTask));
  const [uploadProgress, setUploadProgress] = useState(0);
  const { open, openModal, closeModal } = useModal();

  const navigate = useNavigate();
  const notify = useNotification();
  const token = getCookie(ACCESS_TOKEN);

  const documentToBeDeleted = useRef("");

  const {
    i18n: { language },
  } = useTranslation();
  const { alert, header, placeholder, labels, modal } = getTranslations(language);

  // --------------- Document handlers ---------------

  const onSuccess = ({ data }: UploadFileResponse) => {
    const { id, title, filename_download } = data;
    const type = filename_download.split(".")[1] as FileType;
    const document: Document = { id, title, type };

    setDocuments((prev) => [document, ...prev]);
    setUploadProgress(0);
    notify(alert.success.documentAttached, "success");
  };

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const value = Math.ceil((progressEvent.progress as number) * 100);

    setUploadProgress(value);
  };

  const { mutate, isLoading: fileUploading } = useMutation<
    UploadFileResponse,
    AxiosError,
    UploadFilePayload
  >(uploadTaskFile, {
    onSuccess,
    onError: () => {
      notify(alert.error.failedToAttachDocument, "error");
    },
  });

  const { mutate: deleteFileFromServer, isLoading: fileDeleting } = useMutation<
    null,
    AxiosError,
    DeleteFilePayload
  >(deleteTaskFiles, {
    onSuccess: () => {
      notify(alert.success.documentDeleted, "success");
      const { current: documentToBeDeletedId } = documentToBeDeleted;
      const updatedDocuments = documents.filter(({ id }) => id !== documentToBeDeletedId);
      setDocuments(updatedDocuments);
      closeModal();

      documentToBeDeleted.current = "";
    },
    onError: () => {
      notify(alert.error.failedToDleteDocument, "error");
    },
  });

  const handleAddDocument = (document: File) => {
    const formData = new FormData();
    formData.append("document", document);
    formData.append("documentName", document.name);

    const payload: UploadFilePayload = {
      token,
      formData,
      onUploadProgress,
    };

    mutate(payload);
  };

  const handleDeleteDocument = () => {
    const { current: id } = documentToBeDeleted;
    const fileIds = [id];

    deleteFileFromServer({ fileIds, token });
  };

  // --------------- On change handlers ---------------

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const document = e.target.files[0];

      if (document && document.type.includes("application")) {
        handleAddDocument(document);
      } else {
        notify(alert.warning.notDocument, "warning");
      }
    }
  };

  // --------------- On click handlers ---------------

  const onBackArrowClick = () => {
    const path = isSelectedTask
      ? `/task/selected?id=${searchParams.get("id")}`
      : "/task-creator";

    navigate(path, { replace: true });
  };

  const onBinIconClick = (id: string) => () => {
    documentToBeDeleted.current = id;
    openModal();
  };

  const onConfirmButtonClick = () => {
    handleDeleteDocument();
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!isSelectedTask) {
      const documentsToBeUploaded = documents.map(({ id }) =>
        createFileObjectForPayload(id),
      );

      sessionStorage.setItem("documents", JSON.stringify(documents));
      sessionStorage.setItem(
        "documentsToBeUploaded",
        JSON.stringify(documentsToBeUploaded),
      );
    }
  }, [documents, isSelectedTask]);

  return (
    <ContentContainer>
      <Container>
        <Header isMobile={isMobile} marginTop noMarginBottom>
          <span className='header-title'>{header.documents}</span>
        </Header>

        {!isSelectedTask && !!documents.length && (
          <Actions flexEnd>
            <CaptureButtonContainer documents>
              <CaptureBtn
                htmlFor={!fileUploading ? "select-document" : ""}
                isLoading={fileUploading || fileDeleting}
                isMobile={isMobile}
                documents
              >
                <AddIcon className='add-icon' />
              </CaptureBtn>
              <input
                type='file'
                id='select-document'
                accept='.pdf,.doc,.docx '
                onChange={onChange}
                style={{
                  visibility: "hidden",
                  width: 0,
                  height: 0,
                  position: "absolute",
                }}
              />
            </CaptureButtonContainer>
          </Actions>
        )}
        <DocumentsContainer>
          {!!documents.length && (
            <DocumentsList>
              {documents.map(({ id, title, type }) => (
                <DocumentItem isMobile={isMobile} key={id}>
                  <FileLink href={createHref(id, token)} target='_self' rel='noreferrer'>
                    <FileIcon fileType={type} />
                    <FileName>{title}</FileName>
                  </FileLink>

                  {!isSelectedTask && (
                    <BinContainer isMobile={isMobile} onClick={onBinIconClick(id)}>
                      <BinIcon className='bin-icon' title='Usuń dokument' />
                    </BinContainer>
                  )}
                </DocumentItem>
              ))}
            </DocumentsList>
          )}

          {fileUploading && (
            <>
              <UploadOverlay />
              <ProgressContainer>
                <UploadProgressBar progress={uploadProgress} />
              </ProgressContainer>
            </>
          )}

          {!documents.length && !fileUploading && (
            <>
              <Placeholder>{placeholder.noDocuments}...</Placeholder>
              <Actions center>
                <CaptureButtonContainer documents>
                  <CaptureBtn
                    htmlFor={!fileUploading ? "select-document" : ""}
                    isLoading={fileUploading || fileDeleting}
                    isMobile={isMobile}
                    documents
                    large
                  >
                    <AddIcon className='add-icon' />
                  </CaptureBtn>
                  <input
                    type='file'
                    id='select-document'
                    accept='.pdf,.doc,.docx '
                    onChange={onChange}
                    style={{
                      visibility: "hidden",
                      width: 0,
                      height: 0,
                      position: "absolute",
                    }}
                  />
                </CaptureButtonContainer>
              </Actions>
            </>
          )}
        </DocumentsContainer>
        <Actions flexEnd>
          <Done
            isMobile={isMobile}
            disabled={fileUploading || fileDeleting}
            onClick={onBackArrowClick}
          >
            {labels.btn.done}
          </Done>
        </Actions>
      </Container>

      <ConfirmationModal
        message={modal.message.document}
        onClick={onConfirmButtonClick}
        onClose={closeModal}
        open={open}
        buttonLabel={labels.btn.delete}
        loading={fileDeleting}
        closeDisabled={fileDeleting}
      />
    </ContentContainer>
  );
}

export default TaskDocuments;

import styled, { css } from "styled-components/macro";

import { device, isMobile } from "utils/utils";

import { ReactComponent as PhotosIcon } from "assets/icons/photos.svg";

import ChevronToggle from "./ChevronToggle";

const Container = styled.div<{ isMobile: boolean }>`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:hover {
        background-color: rgba(232, 235, 241, 0.15);
      }
    `}
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .photos-icon {
    width: 2.2rem;
  }
`;

const PhotosNumber = styled.span`
  font-size: 1.4rem;

  @media ${device.iPad} {
    font-size: 1.6rem;
  }

  &::before {
    content: "(";
  }

  &::after {
    content: ")";
  }
`;

type PhotosInfoProps = {
  id: string;
  photosNumber: number;
  expanded: boolean;
  toggleAccordion: (id: string) => () => void;
};

function PhotosPanel({ id, expanded, photosNumber, toggleAccordion }: PhotosInfoProps) {
  return (
    <Container isMobile={isMobile} onClick={toggleAccordion(id)}>
      <Label>
        <PhotosIcon className='photos-icon' />
        <PhotosNumber>{photosNumber}</PhotosNumber>
      </Label>
      <ChevronToggle id={id} expanded={expanded} toggleAccordion={toggleAccordion} />
    </Container>
  );
}

export default PhotosPanel;

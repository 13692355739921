import { UIEvent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";

import { NumberOfPhotos, PhotoPreview } from "../../types/tableOfContents.types";
import { Answer as AnswerType, FormTypes, QuestionnaireObject } from "types/types";
import usePhotosForAnswers from "hooks/usePhotosForAnswers";
import { getMinMaxPhotos } from "pages/questionnaire/utils/utils";
import { isMobile, device } from "utils/utils";

import { ReactComponent as UpArrowIcon } from "assets/icons/up-arrow.svg";

import Answer from "../answer/Answer";

const Container = styled.div`
  overflow: hidden;
  position: relative;
`;

const Wrapper = styled.div<{ isLongList: boolean }>`
  overflow: auto;
  ${({ theme }) => theme.fillUpRemainingSpace};

  ${({ isLongList }) =>
    isLongList &&
    css`
      height: 90%;
    `}

  @media ${device.iPad} {
    height: 95%;
  }
`;

const List = styled.ul`
  margin-top: 1rem;
  padding-bottom: 0.2rem;
  ${({ theme }) => theme.scrollbarFirefox}
`;

const QuestionItem = styled.li<{
  isMobile: boolean;
  required: boolean;
  isAnswered: boolean;
}>`
  position: relative;
  height: 10rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  display: flex;
  gap: 0.25rem;
  border-radius: 0.4rem;
  color: ${({ theme }) => theme.snapcheckBlue};
  ${({ theme }) => theme.cardShadow};
  overflow: hidden;
  transition: filter 0.2s ease-out;
  background-color: ${({ theme }) => theme.aliceBlue};

  ${({ isAnswered }) =>
    isAnswered &&
    css`
      background-color: rgba(240, 248, 255, 0.3);
      color: ${({ theme }) => theme.graphiteGrey};

      /* opacity: 0.75; */
    `}

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:hover {
        cursor: pointer;
        filter: brightness(0.98);
      }
    `}
`;

const QuestionNumber = styled.span``;

const Question = styled.div<{ isAnswered: boolean }>`
  max-height: 3.8rem;
  margin-bottom: 0.4rem;
  padding-right: 1rem;
  ${({ theme }) => theme.ellipsisMultiline}
  font-family: GothamBold;
  font-size: 1.4rem;
  opacity: 0.9;

  .asterisk {
    color: crimson;
    font-size: 1.6em;
    display: inline-block;
    transform: translate(1px, 17px);

    ${({ isAnswered }) =>
      isAnswered &&
      css`
        opacity: 0.5;
      `}
  }

  @media screen and (min-height: 616px) {
    font-size: 1.6rem;
  }
`;

const UpArrowContainer = styled.div<{
  isMobile: boolean;
}>`
  position: absolute;
  right: 0rem;
  bottom: 0.5rem;
  width: 4rem;
  height: 4rem;
  padding: 0.7rem;
  border-radius: 0.6rem;
  animation: showUpArrow 0.4s ease;

  @media ${device.iPad} {
    bottom: -0.5rem;
  }

  @keyframes showUpArrow {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .up-arrow {
    fill: ${({ theme }) => theme.primary};
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      cursor: pointer;
    `}
`;

type QuestionsListProps = {
  selectedQuestionnaire: QuestionnaireObject | undefined;
  onQuestionClick: (index: number) => () => void;
  answers: AnswerType[];
};

const { QUESTIONNAIRE } = FormTypes;

function QuestionsList({
  selectedQuestionnaire,
  onQuestionClick,
  answers,
}: QuestionsListProps) {
  const [arrowVisible, setArrowVisible] = useState(false);
  const { photosForAnswers } = usePhotosForAnswers(QUESTIONNAIRE);

  const listRef = useRef<HTMLDivElement>(null);

  const isLongList = selectedQuestionnaire
    ? selectedQuestionnaire?.Questions.length > 4
    : false;

  let content = null;

  const showArrow = () => {
    setArrowVisible(true);
  };

  const hideArrow = () => {
    setArrowVisible(false);
  };

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    if (listRef.current) {
      const scrollPosition = listRef.current.scrollTop;

      sessionStorage.setItem("scrollPosition", scrollPosition.toString());

      if (scrollPosition > 300) {
        if (!arrowVisible) {
          showArrow();
        }
      } else {
        if (arrowVisible) {
          hideArrow();
        }
      }
    }
  };

  const onUpArrowClick = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  if (selectedQuestionnaire) {
    const { Questions } = selectedQuestionnaire;

    content = (
      <List>
        {Questions.map((question, index) => {
          const answer = answers.find((answer) => answer.Question === question.id);
          const { minPhotos } = getMinMaxPhotos(question);

          let numberOfPhotos: NumberOfPhotos;
          let photoPreview: PhotoPreview;

          if (photosForAnswers) {
            numberOfPhotos = photosForAnswers[question.id]
              ? photosForAnswers[question.id].length
              : undefined;

            if (numberOfPhotos) {
              photoPreview = photosForAnswers[question.id]
                ? photosForAnswers[question.id][numberOfPhotos - 1].src
                : undefined;
            }
          }

          return (
            <QuestionItem
              key={question.id}
              onClick={onQuestionClick(index)}
              isMobile={isMobile}
              required={question.Required}
              isAnswered={!!answer}
            >
              <QuestionNumber>{index + 1}&#x2024;</QuestionNumber>

              <Question isAnswered={!!answer}>
                {question.QuestionText.trim()}
                {question.Required && <sup className='asterisk'>*</sup>}
              </Question>
              <Answer
                answer={answer}
                numberOfPhotos={numberOfPhotos}
                minPhotos={minPhotos}
                photoPreview={photoPreview}
                required={question.Required}
              />
            </QuestionItem>
          );
        })}
      </List>
    );
  }

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (!scrollPosition || !listRef.current) return;

    listRef.current.scrollTo({
      top: Number(scrollPosition),
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <Container>
      <Wrapper onScroll={onScroll} ref={listRef} isLongList={isLongList}>
        {content}

        {arrowVisible && (
          <UpArrowContainer isMobile={isMobile} onClick={onUpArrowClick}>
            <UpArrowIcon className='up-arrow' />
          </UpArrowContainer>
        )}
      </Wrapper>
    </Container>
  );
}

export default QuestionsList;

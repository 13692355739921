import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { isMobile } from "utils/utils";
import UserContext from "contexts/user-context/UserContext";
import { RelatedItem, RelatedCollection, CreatedTask } from "types/tasks.types";
import { getTranslations as getRelatedTranslations } from "translations/related.translations";
import { getTranslations } from "pages/machine-process-info/translations/machine-process.translations";

import BackArrow from "components/atoms/BackArrow";
import Content from "./components/related-content/Content";
import SerialNumber from "./components/serial-number/SerialNumber";
import Department from "./components/department/Department";
import Status from "./components/status/Status";
import Misc from "./components/additional-misc/Misc";
import File from "./components/file/File";

import { ContentContainer } from "styles/generalStyles";
import { Container } from "./styles/task-related.styles";
import { Header } from "pages/task-creator/styles/task-creator.styles";

const { ASSESSMENTS, MACHINES, PROCESSES, STATEMENTS } = RelatedCollection;

function TaskRelated() {
  const navigate = useNavigate();
  const params = useParams();
  const relatedId = Number(params.relatedId);
  const data = JSON.parse(
    sessionStorage.getItem("taskRelated") as string,
  ) as RelatedItem[];
  const relatedItem = data.find(({ id }) => id === relatedId) as RelatedItem;
  const { id: selectedTaskId } = JSON.parse(
    sessionStorage.getItem("selectedTask") as string,
  ) as CreatedTask;

  const {
    PERMISSIONS: { MACHINES_PERMISSIONS, PROCESSES_PERMISSIONS },
  } = useContext(UserContext);

  const {
    i18n: { language },
  } = useTranslation();

  const { header } = getRelatedTranslations(language);
  const { labels } = getTranslations(language);

  let content = null;

  const onBackArrowClick = () => {
    const path = `/task/selected/?id=${selectedTaskId}`;

    navigate(path, { replace: true });
  };

  switch (relatedItem.collection) {
    case ASSESSMENTS:
      content = (
        <Content
          header={header.assesment}
          title={relatedItem.item?.Questionnaire?.QuestionnaireTitle ?? "---"}
        >
          <SerialNumber
            label={labels.serialNumber}
            serialNumber={relatedItem?.item?.SerialNo ?? "---"}
          />
          <File file={relatedItem?.item?.File} />
        </Content>
      );
      break;
    case STATEMENTS:
      content = (
        <Content
          header={header.alarm}
          title={relatedItem.item?.AccidentForm?.Title ?? "---"}
        >
          <File file={relatedItem?.item?.File} />
        </Content>
      );
      break;
    case MACHINES:
      content = (
        <Content header={header.machine} title={relatedItem.item?.MachineModel ?? "---"}>
          {(MACHINES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
            MACHINES_PERMISSIONS.PERMITTED_TO_READ.SerialNo) && (
            <SerialNumber
              label={labels.serialNumber}
              serialNumber={relatedItem?.item?.SerialNo ?? "---"}
            />
          )}
          {(MACHINES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
            MACHINES_PERMISSIONS.PERMITTED_TO_READ.Department) && (
            <Department
              label={labels.department}
              departmentName={relatedItem.item?.Department?.DepartmentName ?? "---"}
            />
          )}
          {(MACHINES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
            MACHINES_PERMISSIONS.PERMITTED_TO_READ.status) && (
            <Status status={relatedItem?.item?.status} />
          )}
          {(MACHINES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
            MACHINES_PERMISSIONS.PERMITTED_TO_READ.Misc) && (
            <Misc misc={relatedItem?.item?.Misc} />
          )}
          {/* <File file={relatedItem?.item?.LastAssessment?.File} /> */}
        </Content>
      );
      break;
    case PROCESSES:
      content = (
        <Content header={header.process} title={relatedItem.item?.ProcessName ?? "---"}>
          {(PROCESSES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
            PROCESSES_PERMISSIONS.PERMITTED_TO_READ.SerialNo) && (
            <SerialNumber
              label={labels.serialNumber}
              serialNumber={relatedItem?.item?.SerialNo ?? "---"}
            />
          )}
          {(PROCESSES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
            PROCESSES_PERMISSIONS.PERMITTED_TO_READ.Department) && (
            <Department
              label={labels.department}
              departmentName={relatedItem.item?.Department?.DepartmentName ?? "---"}
            />
          )}
          {(PROCESSES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
            PROCESSES_PERMISSIONS.PERMITTED_TO_READ.status) && (
            <Status status={relatedItem?.item?.status} />
          )}
          {(PROCESSES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
            PROCESSES_PERMISSIONS.PERMITTED_TO_READ.Misc) && (
            <Misc misc={relatedItem?.item?.Misc} />
          )}
          {/* <File file={relatedItem?.item?.LastAssessment?.File} /> */}
        </Content>
      );
  }

  return (
    <ContentContainer>
      <Container>
        <Header isMobile={isMobile} marginTop>
          <span className='header-title'>{header.title}</span>
          <BackArrow onClick={onBackArrowClick} customClass='back-arrow' />
        </Header>
        {content}
      </Container>
    </ContentContainer>
  );
}

export default TaskRelated;

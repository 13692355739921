import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UserContext from "contexts/user-context/UserContext";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import useMachineProcessInfo from "hooks/useMachineProcessInfo";
import { convertToString, isMobile, statusColors } from "utils/utils";
import { getTranslations } from "./translations/machine-process.translations";

import Button from "components/atoms/Button";
import Linkify from "./components/Linkify";
import TasksInProgress from "./components/tasks-in-progress/TasksInProgress";

import { ContentContainer } from "styles/generalStyles";
import { Tab, Tabs, TabLabel, SliderContainer, Slider } from "styles/tabs.styles";
import {
  Actions,
  ContentWrapper,
  InfoLine,
  InfoList,
  Label,
  Loader,
  Placeholder,
  Shimmer,
  StatusDot,
  StatusValue,
  TasksNumber,
  Value,
} from "./styles/machine-process-info.styles";

enum TabsEnum {
  INFO = "info",
  IN_PROGRESS = "in_progress",
}

type TabType = TabsEnum.INFO | TabsEnum.IN_PROGRESS;

const { INFO, IN_PROGRESS } = TabsEnum;

function MachineProcessInfo() {
  const {
    state: {
      machineProcess: { Machine, Process },
      serialNumber,
    },
  } = useContext(QuestionnairesContext);
  const {
    PERMISSIONS: { MACHINES_PERMISSIONS, PROCESSES_PERMISSIONS },
  } = useContext(UserContext);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [tasksNumber, setTasksNumber] = useState(0);
  const {
    t,
    i18n: { language },
  } = useTranslation(["common"]);
  const { tabs, labels, noDescription } = getTranslations(language);
  const { statusTranslated, addressLabel } = useMachineProcessInfo();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get("tab");

  let sliderPosition = currentTab === IN_PROGRESS ? 100 : 0;
  let title = "";
  let content = null;

  if (Machine) {
    title = tabs.machine;
    const { MachineModel, Make, ServiceExpiryDate, Misc, status, HourMeter } = Machine;
    const { PERMITTED_TO_READ } = MACHINES_PERMISSIONS;

    content = (
      <InfoList>
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.MachineModel) && (
          <InfoLine>
            <Label>{labels.model}:</Label>
            <Value>{MachineModel}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.Make) && (
          <InfoLine>
            <Label>{labels.make}:</Label>
            <Value>{Make}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.status) && (
          <InfoLine>
            <Label>{labels.status}:</Label>
            <Value>
              <StatusValue>
                <StatusDot status={status} statusColors={statusColors} />
                <span>{statusTranslated}</span>
              </StatusValue>
            </Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.SerialNo) && (
          <InfoLine>
            <Label>{labels.serialNumber}:</Label>
            <Value>{serialNumber}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.ServiceExpiryDate) && (
          <InfoLine>
            <Label>{labels.serviceDate}:</Label>
            <Value>{convertToString(ServiceExpiryDate)}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.HourMeter) && (
          <InfoLine>
            <Label>{labels.hourMeter}:</Label>
            <Value>{HourMeter}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.Misc) && (
          <InfoLine isMisc>
            {!!Misc ? (
              <Linkify text={Misc} />
            ) : (
              <Placeholder>{noDescription}</Placeholder>
            )}
          </InfoLine>
        )}
      </InfoList>
    );
  }

  if (Process) {
    title = tabs.process;
    const { ProcessName, ServiceExpiryDate, Misc, Address, status } = Process;
    const { PERMITTED_TO_READ } = PROCESSES_PERMISSIONS;

    content = (
      <InfoList>
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.ProcessName) && (
          <InfoLine>
            <Label>{labels.name}:</Label>
            <Value>{ProcessName}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.SerialNo) && (
          <InfoLine>
            <Label>{labels.serialNumber}:</Label>
            <Value>{serialNumber}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.status) && (
          <InfoLine>
            <Label>{labels.status}:</Label>
            <Value>
              <StatusValue>
                <StatusDot status={status} statusColors={statusColors} />
                <span>{statusTranslated}</span>
              </StatusValue>
            </Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.ServiceExpiryDate) && (
          <InfoLine>
            <Label>{labels.serviceDate}:</Label>
            <Value>{convertToString(ServiceExpiryDate)}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.Address) && (
          <InfoLine>
            <Label>{addressLabel}:</Label>
            <Value>{Address}</Value>
          </InfoLine>
        )}
        {(PERMITTED_TO_READ.ALL || PERMITTED_TO_READ.Misc) && (
          <InfoLine isMisc>
            {!!Misc ? (
              <Linkify text={Misc} />
            ) : (
              <Placeholder>{noDescription}</Placeholder>
            )}
          </InfoLine>
        )}
      </InfoList>
    );
  }

  const onBackButtonClick = () => {
    const infoReturnPath = localStorage.getItem("infoReturnPath") ?? "";
    navigate(infoReturnPath, { replace: true });
    localStorage.removeItem("infoReturnPath");
  };

  const onTabClick = (tab: TabType) => () => {
    if (tasksLoading) return;

    setSearchParams({ tab }, { replace: true });
  };

  useEffect(() => {
    if (currentTab === INFO) {
      setTasksNumber(0);
    }
  }, [currentTab]);

  return (
    <ContentContainer>
      <Tabs>
        <Tab active={currentTab === INFO} isMobile={isMobile} onClick={onTabClick(INFO)}>
          <TabLabel>{title}</TabLabel>
        </Tab>
        {tasksLoading ? (
          <Loader>
            <TabLabel>{tabs.inProgress}</TabLabel>
            <Shimmer />
          </Loader>
        ) : (
          <Tab
            active={currentTab === IN_PROGRESS}
            isMobile={isMobile}
            onClick={onTabClick(IN_PROGRESS)}
          >
            <TabLabel>
              <span>{tabs.inProgress}</span>
              {!!tasksNumber && <TasksNumber>{tasksNumber}</TasksNumber>}
            </TabLabel>
          </Tab>
        )}
      </Tabs>
      <SliderContainer>
        <Slider position={sliderPosition} />
      </SliderContainer>

      <ContentWrapper>
        {currentTab === INFO && content}

        {currentTab === IN_PROGRESS && (
          <TasksInProgress
            setTasksLoading={setTasksLoading}
            setTasksNumber={setTasksNumber}
          />
        )}

        <Actions>
          <Button
            label={t("common:button.back")}
            customClass='button'
            onClick={onBackButtonClick}
          />
        </Actions>
      </ContentWrapper>
    </ContentContainer>
  );
}

export default MachineProcessInfo;

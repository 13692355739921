import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import Thumb from "components/atoms/Thumb";
import { CountryCodes } from "types/types";

const Container = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  @media screen and (min-height: 508px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

const Label = styled.span<{ up?: boolean; down?: boolean; isZero: boolean }>`
  flex-basis: 15%;
  height: 100%;

  display: grid;
  place-content: center;

  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;

  ${({ up }) =>
    up &&
    css`
      background-color: ${({ theme }) => theme.yesButton};
    `}

  ${({ down }) =>
    down &&
    css`
      background-color: ${({ theme }) => theme.noButton};
    `}

    ${({ isZero }) =>
    isZero &&
    css`
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    `}

  .thumb {
    height: 2rem;
    transform: translateY(0);
  }

  .down {
    transform: scaleX(-1) translateY(0);
  }
`;

const Bar = styled.div<{ positive?: boolean; negative?: boolean; value: number }>`
  flex-basis: 85%;
  height: 100%;
  position: relative;

  &::before {
    content: "";
    width: ${({ value }) => `${value}%`};
    height: 100%;
    background-color: ${({ theme, positive }) =>
      positive ? theme.yesButton : theme.noButton};
    position: absolute;
    top: 0;
    left: 0;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;

const Value = styled.div<{ isDark: boolean }>`
  width: 100%;
  color: ${({ isDark, theme }) => (isDark ? theme.primary : theme.white)};
  font-size: 1.5rem;
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
`;

const Neutral = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.jetGrey};
  height: 100%;
  border-radius: 0.4rem;
  color: ${({ theme }) => theme.white};
  padding: 0 1rem;
  font-size: 1.4rem;
`;

const NeutralLabel = styled.span``;
const NeutralValue = styled.span``;

const Divider = styled.div`
  height: 4rem;
`;

type StatisticsProps = {
  percentage: {
    positive: number;
    negative: number;
    neutral: number;
  };
};

const getTranslatioins = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  return isPolish ? "Neutralne" : "Neutral";
};

function Statistics({ percentage: { positive, negative, neutral } }: StatisticsProps) {
  const {
    i18n: { language },
  } = useTranslation();

  const neutralLabel = getTranslatioins(language);

  let content = null;

  if (!positive && !negative) {
    content = <Divider />;
  }

  if (positive || negative || neutral) {
    content = (
      <Container>
        <Line>
          <Label up isZero={!positive}>
            <Thumb up customClass='thumb' />
          </Label>
          <Bar positive value={positive}>
            <Value isDark={!positive}>{positive.toFixed(0)}%</Value>
          </Bar>
        </Line>
        <Line>
          <Label down isZero={!negative}>
            <Thumb down customClass='thumb down' />
          </Label>
          <Bar negative value={negative}>
            <Value isDark={!negative}>{negative.toFixed(0)}%</Value>
          </Bar>
        </Line>

        {!!neutral && (
          <Line>
            <Neutral>
              <NeutralLabel>{neutralLabel}:</NeutralLabel>
              <NeutralValue>{neutral}</NeutralValue>
            </Neutral>
          </Line>
        )}
      </Container>
    );
  }

  return content;
}

export default Statistics;

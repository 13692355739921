import styled, { css } from "styled-components/macro";

import { YesNoAnswer } from "types/types";

import Thumb from "components/atoms/Thumb";

const Container = styled.div<{ flexStart: boolean; flexBasis: number }>`
  flex-basis: ${({ flexBasis }) => `${flexBasis}%`};
  display: flex;
  justify-content: "flex-end";

  ${({ flexStart }) =>
    flexStart &&
    css`
      width: 3.5rem;
    `}
`;

const ThumbIcon = ({
  value,
  flexStart = false,
  flexBasis = 7,
}: {
  value: YesNoAnswer;
  flexStart?: boolean;
  flexBasis?: number;
}) => {
  const up = value === "Yes";
  const down = value === "No";
  const customClass = up ? "icon-yes" : "icon-no";

  return (
    <Container flexStart={flexStart} flexBasis={flexBasis}>
      <Thumb up={up} down={down} filled customClass={customClass} />
    </Container>
  );
};

export default ThumbIcon;

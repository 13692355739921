import { ChangeEvent } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { AlertNames } from "../types/summary.types";

import { ReactComponent as AlertIcon } from "assets/icons/alert-exclamation.svg";

import ConfirmationCheckbox from "./ConfirmationCheckbox";

const Container = styled.div`
  margin-bottom: 1.5rem;
`;

const IconContainer = styled.div`
  .icon {
    width: 5rem;
    path {
      fill: ${({ theme }) => theme.errorColor};
    }
  }
`;

const InfoContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: ${({ theme }) => theme.white};
  border: 4px solid ${({ theme }) => theme.errorColor};
  border-radius: 0.6rem;
  font-size: 1.6rem;
  font-family: GothamBold;

  display: flex;
  align-items: center;
`;

const Info = styled.div`
  padding-right: 1rem;
  max-height: 12rem;
  overflow-y: auto;
`;

type GravityAlertProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  gravityAlertInfo: string | null;
};

const { GRAVITY } = AlertNames;

function GravityAlert({ onChange, gravityAlertInfo }: GravityAlertProps) {
  const { t } = useTranslation("summary");
  const __html = gravityAlertInfo ? gravityAlertInfo : "";

  return (
    <Container>
      <InfoContainer>
        <IconContainer>
          <AlertIcon className='icon' />
        </IconContainer>
        <Info dangerouslySetInnerHTML={{ __html }} />
      </InfoContainer>
      <ConfirmationCheckbox
        label={t("gravity-alert.checkbox-label")}
        onChange={onChange}
        name={GRAVITY}
      />
    </Container>
  );
}

export default GravityAlert;

import { Answer, QuestionType } from "types/types";

type PromptProps = {
  onClick: () => void;
  questionType: QuestionType;
  currentQuestionId?: string;
  answerToCurrentQuestion: Answer | undefined;
};

export interface TextPromptProps extends PromptProps {
  descriptionMissing?: boolean;
}

export interface PhotoPromptProps extends PromptProps {
  minPhotos: number | null | undefined;
  maxPhotos: number | null | undefined;
  minPhotosNotReached?: boolean;
}

export enum PromptType {
  TEXT = "text",
  PHOTO = "photo",
}

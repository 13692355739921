import styled from "styled-components/macro";

export const ActionsWrapper = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace}
  justify-content: flex-end;
  padding-bottom: 1.25rem;

  @media screen and (min-height: 508px) {
    padding-bottom: 2rem;
  }
`;

import { ChangeEvent, RefObject } from "react";
import { IDetectedBarcode } from "@yudiel/react-qr-scanner";

import { isMobile } from "utils/utils";
import { QuestionType, TextLength } from "types/types";
import useScreen from "hooks/useScreen";
import useTextLength from "hooks/useTextLength";
import useBarcodeScannerAccess from "hooks/useBarcodeScannerAccess";
import { getTranslations as getTextAreaTranslations } from "translations/textarea.translations";

import { ReactComponent as BarCodeIcon } from "assets/icons/barcode.svg";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";

import TextareaBarcodeScanner from "components/molecules/textarea-barcode-scanner/TextareaBarcodeScanner";
import Dictaphone from "components/molecules/Dictaphone";
import Button from "components/atoms/Button";

import {
  Actions,
  BarcodeTextIconsContainer,
  NextBtnContainer,
} from "../../styles/question-actions.styles";

type InputTextProps = {
  onScan: (result: IDetectedBarcode[]) => void;
  readOnly?: boolean;
  textareaRef: RefObject<HTMLTextAreaElement>;
  minTextLength?: TextLength;
  maxTextLength?: TextLength;
  textAreaValue: string;
  onTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement> | string) => void;
  isBarcodeScannerVisible: boolean;
  isDictaphoneAccessible: boolean;
  onTextTranscriptChange: (value: string) => void;
  questionType: QuestionType;
  searchParams: URLSearchParams;
  onBarcodeIconClick: () => void;
  onTextIconClick: () => void;
  onNextClick: () => void;
  btnLabel: string;
  language: string;
  required: boolean;
};

function InputText({
  onScan,
  textareaRef,
  minTextLength,
  maxTextLength,
  textAreaValue,
  onTextAreaChange,
  isBarcodeScannerVisible,
  isDictaphoneAccessible,
  onTextTranscriptChange,
  questionType,
  searchParams,
  onBarcodeIconClick,
  onTextIconClick,
  btnLabel,
  onNextClick,
  language,
  required,
}: InputTextProps) {
  const { hasAccessToBarcodeScanner } = useBarcodeScannerAccess();
  const { smallView } = useScreen();
  const { labels } = getTextAreaTranslations(language);
  const isListening = !!searchParams.get("listening");

  const { textLength, minText, maxText, isTextTooLong, errorMessage } = useTextLength(
    textAreaValue,
    minTextLength,
    maxTextLength,
  );

  let disabled = isListening;

  if (required) {
    disabled = isListening || textLength < minText || isTextTooLong;
  }

  return (
    <>
      <TextareaBarcodeScanner
        labels={labels}
        onScan={onScan}
        textareaRef={textareaRef}
        errorMessage={errorMessage}
        minText={minText}
        maxText={maxText}
        textAreaValue={textAreaValue}
        isBarcodeScannerVisible={isBarcodeScannerVisible}
        onTextAreaChange={onTextAreaChange}
        required={required}
      />
      <Actions spaceBetween={isDictaphoneAccessible}>
        {isDictaphoneAccessible && (
          <Dictaphone
            onTextTranscriptChange={onTextTranscriptChange}
            questionType={questionType}
            answerTextAreaValue={textAreaValue}
            isBarcodeScannerVisible={isBarcodeScannerVisible}
          />
        )}
        {hasAccessToBarcodeScanner && !isListening && (
          <BarcodeTextIconsContainer
            isMobile={isMobile}
            isDictaphoneAccessible={isDictaphoneAccessible}
          >
            {!isBarcodeScannerVisible ? (
              <BarCodeIcon className='icon' onClick={onBarcodeIconClick} />
            ) : (
              <TextIcon className='icon' onClick={onTextIconClick} />
            )}
          </BarcodeTextIconsContainer>
        )}
        <NextBtnContainer smallView={smallView}>
          <Button
            label={btnLabel}
            large
            question
            onClick={onNextClick}
            disabled={disabled}
          />
        </NextBtnContainer>
      </Actions>
    </>
  );
}

export default InputText;

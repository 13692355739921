export const containsSerial = (value: string) =>
  value.includes("SerialNo=") || value.includes("serial=");

export const getSerialNumberFromUrl = (url: string) => {
  const array = url.split("=");
  const serial = array[array.length - 1];

  return serial || "";
};

export const checkIfUrl = (value: string) =>
  value.includes("https") || value.includes("http");

import styled, { css } from "styled-components/macro";

import { device } from "utils/utils";

export const Header = styled.div<{
  smallView?: boolean;
  marginTop?: boolean;
  paddingBottom?: boolean;
}>`
  position: relative;
  border-bottom: ${({ theme }) => `1px solid ${theme.primary}`};
  padding-top: 1.5rem;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? "3rem" : "1.5rem")};
  margin-top: ${({ marginTop }) => (marginTop ? "2rem" : "0")};

  display: flex;
  align-items: center;

  ${({ smallView }) =>
    smallView &&
    css`
      padding-bottom: 0.7rem;
    `}
`;

export const ListIconContainer = styled.div<{ isMobile: boolean; disabled: boolean }>`
  flex-basis: 22%;
  display: flex;
  align-items: center;

  .list-icon {
    width: 3rem;
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
    transition: opacity 0.15s ease-out;

    path {
      fill: ${({ theme }) => theme.primary};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.25;
        cursor: not-allowed;
      `}
  }
`;

export const QuestionNumberContainer = styled.div`
  flex-basis: 56%;
  font-family: GothamBold;
  display: flex;
  justify-content: center;

  .check-icon {
    width: 2.1rem;
    position: absolute;
    right: -1.9rem;
    bottom: 0.9rem;

    path {
      stroke: ${({ theme }) => theme.primary};
    }
  }

  .warning-icon {
    width: 4.2rem;
    height: 4.2rem;
  }
`;

export const QuestionNumber = styled.div<{ accident?: boolean }>`
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-size: ${({ accident }) => (accident ? "2.2rem" : "2.6rem")};
  color: ${({ theme }) => theme.primary};
  user-select: none;
`;

export const Slash = styled.span`
  &::after {
    content: "/";
    font-family: "GothamBook";
  }
`;

export const ArrowsContainer = styled.div<{ forward?: boolean }>`
  flex-basis: 22%;
  display: flex;
  align-items: center;
  justify-content: ${({ forward }) => (forward ? "flex-end" : "space-between")};

  .forward {
    transform: rotate(180deg);
  }
`;

export const Arrow = styled.div<{
  isHidden?: boolean;
  isMobile?: boolean;
  isLoading?: boolean;
  isBlocked?: boolean;
}>`
  width: 3rem;
  height: 2.2rem;
  visibility: ${({ isHidden }) => (isHidden ? "hidden" : "visible")};
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
  transition: opacity 0.15s ease-out;

  ${({ isLoading, isBlocked }) =>
    (isLoading || isBlocked) &&
    css`
      cursor: not-allowed;
      opacity: 0.25;
    `}

  .chevron-icon {
    width: 100%;
    height: 100%;
  }
`;

export const MainField = styled.div<{ accident?: boolean; hidden: boolean }>`
  position: relative;
  height: 100%;

  ${({ accident }) =>
    accident &&
    css`
      height: 80%;
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      height: 0;
    `}
`;

export const NoQuestions = styled.div`
  flex-basis: 80%;
  text-align: center;
  font-style: italic;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .warning-icon {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.primary};
  }
`;

export const QuestionContainer = styled.div`
  position: relative;
  font-size: 2rem;
  font-family: GothamBold;

  @media screen and (min-height: 616px) {
    font-size: 2.2rem;
  }
`;

export const Question = styled.div`
  position: relative;
`;

export const SubText = styled.div`
  font-size: 1.8rem;
  margin-top: 0.5rem;
  -webkit-line-clamp: 1;

  @media screen and (min-height: 617px) {
    font-size: 2rem;
  }
`;

export const HeaderLabel = styled.div<{ fullWidth?: boolean }>`
  font-size: 1.6rem;

  @media screen and (min-width: 360px) {
    font-size: 1.8rem;
  }

  font-family: GothamBold;
  text-transform: uppercase;
  white-space: nowrap;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-height: 616px) {
    top: 50%;
  }

  @media ${device.iPad} {
    font-size: 2.2rem;
    top: 55%;
  }
`;

import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let message = {
    top: "No camera access",
    bottom: "Camera is probably being used by another application",
    scanBarcode: "Scan barcode",
  };

  let button = {
    label: "Refresh",
  };

  if (isPolish) {
    message = {
      top: "Brak dostępu do kamery",
      bottom: "Kamera jest prawdopodobnie używana przez inną aplikację",
      scanBarcode: "Zeskanuj kod kreskowy",
    };

    button = {
      label: "Odśwież",
    };
  }

  return { message, button };
};

import { useTranslation } from "react-i18next";

import { TextLength } from "types/types";
import { getTranslations } from "translations/textarea.translations";

const getNumberValue = (value: TextLength) => (value ? value : 1);

const getMaxTextLength = (minTextLength: TextLength, maxTextLength: TextLength) => {
  const minText = getNumberValue(minTextLength);
  const maxText = getNumberValue(maxTextLength);

  if (minText > maxText) {
    return minText;
  }

  return maxText;
};

function useTextLength(
  textValue: string,
  minTextLength: TextLength,
  maxTextLength: TextLength,
) {
  const {
    i18n: { language },
  } = useTranslation();
  const { error } = getTranslations(language);

  const minText = getNumberValue(minTextLength);
  const maxText = getMaxTextLength(minTextLength, maxTextLength);

  const textLength = textValue.length;
  const isTextTooLong = textLength > maxText;
  const isMinText = textLength >= minText;

  const errorMessage = isTextTooLong
    ? `${error.textTooLong} [${textLength}/${maxText}]`
    : "";

  return { textLength, minText, maxText, isTextTooLong, isMinText, errorMessage };
}

export default useTextLength;

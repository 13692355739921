import { AxiosProgressEvent } from "axios";
import { UserOption } from "./assign-to-user.types";
import { CollectionEnum } from "./permissions.types";
import { MachineProcessStatus } from "./types";

export enum EditModalTypes {
  TITLE = "Title",
  COMMENT = "Comment",
}

export enum ReturnPathKeys {
  SELECTED_TASK = "selectedTaskReturnPath",
  TASK_CREATOR = "taskCreatorReturnPath",
  CHANGE_STATUS = "changeStatusReturnPath",
}

export enum TaskStatuses {
  TO_DO = "todo",
  IN_PROGRESS = "in_progress",
  DONE = "done",
  ARCHIVED = "archived",
}

export type RelatedFile = {
  File: { filename_disk: string; filename_download: string; title: string };
};

export type DirectusFile = {
  directus_files_id: {
    id: string;
    type: string;
    title: string;
    filename_download: string;
  } | null;
};

export enum RelatedCollection {
  ASSESSMENTS = "Assessments",
  STATEMENTS = "Statements",
  MACHINES = "Machines",
  PROCESSES = "Processes",
}

export type CreatedTask = {
  AssignTo: string;
  Company: string;
  Deadline: string | null;
  Description: string;
  Files: DirectusFile[];
  Related: {
    id: number;
    collection: RelatedCollection;
  }[];
  Title: string;
  date_created: Date | number;
  date_updated: Date;
  id: string;
  sort: number | null;
  status: TaskStatuses;
  user_created: string;
  user_updated: string;
};

export enum FileType {
  PDF = "pdf",
  PNG = "png",
  JPG = "jpg",
  JPEG = "jpeg",
  DOC = "doc",
  DOCX = "docx",
}

export type GetCreatedTasksResponse = {
  data: CreatedTask[];
};

type TaskCollections = CollectionEnum | RelatedCollection;

export type Create = {
  Tasks_id: string;
  collection: TaskCollections;
  item: {
    id: string;
  };
}[];

export type Related = {
  create: Create;
};

export type FilesCreate = {
  Task_id: "+";
  directus_files_id: {
    id: string;
  };
};

export type Files = {
  create: FilesCreate[];
  delete: {
    directus_files_id: {
      id: string;
    };
    Tasks_id: string;
  }[];
};

export type CreateTaskPayload = {
  AnswersLinkWithTask: string[];
  AssignTo: string | null;
  Deadline: string | null;
  Description: string;
  Files: Files;
  Related: Related;
  Title: string;
  status: TaskStatuses;
  token: string;
};

export type TaskStatusOption = {
  id: TaskStatuses;
  label: string | JSX.Element;
  value: TaskStatuses;
};

export type EditTaskPayload = {
  id: string;
  AssignTo?: string;
  Description?: string;
  Deadline?: string | null;
  Title?: string;
  status?: TaskStatuses;
  token: string;
};

export type EditTaskResponse = {
  data: CreatedTask;
};

export enum TaskPermissionCollections {
  TASKS = "Tasks",
  TASKS_RELATED = "Tasks_Related",
  TASKS_FILES = "Tasks_files",
}

export type PhotoGallery = {
  file: File;
  // src: string;
  photoId: string;
}[];

export type TaskPhotoObjectStoreItem = {
  PhotoGallery: PhotoGallery;
  Question: string;
  created: Date;
};

export type NewTask = {
  id: string;
  title: string;
  comment: string;
  deadline: string | null;
  selectedUser: UserOption | null;
};

export type UploadFilePayload = {
  token: string;
  formData: FormData;
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
};

export type DeleteFilePayload = {
  token: string;
  fileIds: string[];
};

export type UploadFileResponse = {
  data: {
    Company: string;
    description: string | null;
    filename_disk: string;
    filename_download: string;
    filesize: string;
    height: number;
    id: string;
    title: string;
    type: string;
    uploaded_by: string;
    uploaded_on: Date;
    width: number;
    Files: number[];
  };
};

export type RelatedItem = {
  id: number;
  collection: RelatedCollection;
  item: {
    Department: {
      DepartmentName: string;
      id: string;
    };
    MachineModel: string;
    SerialNo: string;
    id: string;
    AccidentForm: {
      Title: string;
      id: string;
    };
    Questionnaire: {
      QuestionnaireTitle: string;
      id: string;
    };
    ProcessName: string;
    LastAssessment: {
      File: string;
    };
    Misc: string;
    status: MachineProcessStatus;
    File: string;
  };
};

export type GetRelatedItemsResponse = {
  data: RelatedItem[];
};

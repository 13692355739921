import styled from "styled-components/macro";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

const Container = styled.span`
  padding: 0.25rem 1rem;
  border-radius: 0.4rem;
  font-size: 1.3rem;
  border: 2px solid;
  background-color: rgba(255, 193, 7, 0.55);
  font-family: GothamBold;
  /* transform: translateX(0.5rem); */

  display: flex;
  align-items: center;
  gap: 0.5rem;

  .warning-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

function MissingAlert({ label }: { label: string }) {
  return (
    <Container>
      <WarningIcon className='warning-icon' />
      <span>{label}</span>
    </Container>
  );
}

export default MissingAlert;

import { Answer, Answers, QUESTION_TYPE_IDS } from "types/types";

const { YES_NO, YES_NO_TEXT, YES_NO_PHOTO } = QUESTION_TYPE_IDS;
const { YES, NO, NA } = Answers;

const getPercentage = (value: number, filteredAnswersLength: number) =>
  +((value / filteredAnswersLength) * 100).toFixed(2) || 0;

const filterAnswers = (answers: Answer[]) =>
  answers.filter(
    ({ QuestionType, Value }) =>
      (QuestionType === YES_NO ||
        QuestionType === YES_NO_TEXT ||
        QuestionType === YES_NO_PHOTO) &&
      Value !== NA,
  );

type PositiveNegative = { positiveAnswers: number; negativeAnswers: number };

function useStatistics(answers: Answer[]) {
  const filteredAnswers = filterAnswers(answers);

  let positiveCount = 0;
  let negativeCount = 0;

  const positiveNegative = filteredAnswers.reduce(
    (acc: PositiveNegative, curr) => {
      const { Value } = curr;

      if (Value === YES) {
        positiveCount += 1;
        acc = { ...acc, positiveAnswers: positiveCount };
      }

      if (Value === NO) {
        negativeCount += 1;
        acc = { ...acc, negativeAnswers: negativeCount };
      }

      return acc;
    },
    { positiveAnswers: 0, negativeAnswers: 0 },
  );

  const { positiveAnswers, negativeAnswers } = positiveNegative;

  const positive = getPercentage(positiveAnswers, filteredAnswers.length);
  const negative = getPercentage(negativeAnswers, filteredAnswers.length);
  const neutral = answers.filter(({ Value }) => Value === NA).length;

  return {
    positive,
    negative,
    neutral,
  };
}

export default useStatistics;
